<template>
    <v-expansion-panels accordion multiple>

        <!--item props-->
        <v-expansion-panel>
            <v-expansion-panel-header>{{ $t('project.3d.element') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table dense>
                    <tr>
                        <th>{{ $t('project.3d.name') }}</th>
                        <td>{{ properties && properties['Name'] && properties['Name'].value ? properties['Name'].value : '-' }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('project.3d.description') }}</th>
                        <td>{{ properties && properties['Description'] && properties['Description'].value ? properties['Description'].value : '-' }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('project.3d.tag') }}</th>
                        <td>{{ properties && properties['Tag'] && properties['Tag'].value ? properties['Tag'].value : '-' }}</td>
                    </tr>
                </v-simple-table>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <!--calc-->
        <v-expansion-panel>
            <v-expansion-panel-header>{{ $t('project.3d.quantities') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-chip v-if="quantities && quantities.error" color="red" text-color="white">
                    <v-avatar left>
                        <v-icon>mdi-alert-circle</v-icon>
                    </v-avatar>
                    {{ quantities.error }}
                </v-chip>
                <span v-else>
                    <v-simple-table v-if="quantities" dense>
                        <template v-slot:default>
                            <tr v-for="(item, i) in Object.keys(quantities)" :key="i">
                                <th style="max-width: 100px; ">{{ $t('project.3d.' + item) }}</th>
                                <td>{{ quantities[item].toFixed(2) }}</td>
                            </tr>
                        </template>
                    </v-simple-table>
                </span>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <!--price-->
        <v-expansion-panel>
            <v-expansion-panel-header>Price</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-chip v-if="price && price.error" color="red" text-color="white">
                    <v-avatar left>
                        <v-icon>mdi-alert-circle</v-icon>
                    </v-avatar>
                    {{ price.error }}
                </v-chip>
                <span v-else>
                    <span v-if="price">
                        <v-icon small>mdi-currency-eur</v-icon> {{ price.toFixed(2) }}
                    </span>
                </span>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'properties',
    props: ['properties', 'quantities', 'price']
};
</script>

<style scoped lang="scss">
table {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto", sans-serif;

    th {
        text-align: left;
        vertical-align: top;
        font-size: 10px;
        font-weight: bold;
        padding-right: 10px;
    }
}
</style>
