<template>
    <div>
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <v-card max-width="400" class="mx-auto mt-12" outlined>
            <div class="d-flex py-12">
                <v-img
                    contain
                    alt="Mooduul"
                    src="@/assets/logo.svg"
                    transition="scale-transition"
                    width="410"
                    max-height="140"
                    margin="mx-auto"
                />
            </div>
            <v-card-text class="px-8 py-12 pt-0">
                <v-form v-model="valid" @submit.prevent="submit">
                    <v-text-field
                        v-model="password"
                        :type="'password'"
                        label="Password"
                        :rules="passwordRules"
                        required
                        outlined
                        :disabled="loading"
                        name="new-password"
                    ></v-text-field>
                    <v-text-field
                        v-model="password2"
                        :type="'password'"
                        label="Confirm Password"
                        :rules="[...passwordRules, passwordsMatching]"
                        required
                        outlined
                        :disabled="loading"
                        name="new-password-2"
                    ></v-text-field>
                    <v-btn
                        class="mr-4"
                        block
                        type="submit"
                        color="primary"
                        :disabled="!valid"
                        :loading="loading"
                        depressed
                    >
                        Reset password
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import AuthInterface from '@/services/AuthInterface';

export default {
    name: 'ResetPass',
    data() {
        return {
            valid: false,
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= 5 || 'Minimum 5 characters required',
                v => v.length <= 30 || 'Maximum 30 characters allowed'
            ],
            passwordsMatching: v => v === this.password || 'Passwords not matching',
            resetToken: this.$route.query.token,
            password: '',
            password2: '',
            error: null,
            showError: false,
            loading: false
        };
    },
    methods: {
        submit(event) {
            event.preventDefault();
            this.loading = true;

            AuthInterface.resetPass({
                resetToken: this.resetToken,
                password: this.password,
                password2: this.password2
            })
                .then(response => {
                    if (response.error) {
                        throw new Error(response.error);
                    }

                    if (response.success) {
                        this.$router.push({path: '/login', name: 'Login', params: {message: 'Your password has been successfully reset!'}});
                    }
                })
                .catch(error => {
                    this.error = error;
                    this.showError = true;
                    this.loading = false;
                });
        }
    }
}
</script>
