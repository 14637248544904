<template>
    <div class="pa-16 fill-height">
        <div class="pb-6 font-weight-bold text-uppercase text-h6">
            <div class="align-center">
                <v-icon x-large left>mdi-folder-open</v-icon>
                {{ project.name }}
            </div>
        </div>

        <v-divider class="mb-6"></v-divider>

        <v-flex md12 lg6>
            <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">{{ $t('project.details.title') }}</div>

            <v-simple-table class="mb-6">
                <tbody class="grey lighten-5">
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-identifier</v-icon>{{ $t('project.details.id') }}:
                    </td>
                    <td>{{ project._id }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-text-box</v-icon> {{ $t('project.details.description') }}:
                    </td>
                    <td>{{ project.description || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-calendar-month</v-icon> {{ $t('project.details.created') }}:
                    </td>
                    <td>{{ project.created | moment('calendar') }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-clipboard-check</v-icon> {{ $t('project.details.status') }}:
                    </td>
                    <td>
                        <v-chip :class="statusColor[project.status]" small class="text-capitalize font-weight-medium">
                            {{ getStatus() }}
                        </v-chip>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-cube</v-icon> {{ $t('project.details.file') }}:
                    </td>
                    <td>
                        <v-select
                            dense
                            v-model="ifcFile"
                            :items="project.files"
                            :item-text="item => item.name"
                            @input="onFileChange"
                        >
                        </v-select>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>

            <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">
                {{ $t('project.details.additional') }}
            </div>

            <v-simple-table class="mb-8">
                <tbody class="grey lighten-5">
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-folder</v-icon> {{ $t('project.details.number') }}:
                    </td>
                    <td>{{ project.projectNumber || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-map-marker</v-icon> {{ $t('project.details.location') }}:
                    </td>
                    <td>{{ project.projectLocation || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-ruler-square</v-icon> {{ $t('project.details.architect') }}:
                    </td>
                    <td>{{ project.architect || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-account-hard-hat</v-icon> {{ $t('project.details.construction') }}:
                    </td>
                    <td>{{ project.constructionCompany || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-office-building</v-icon> {{ $t('project.details.office') }}:
                    </td>
                    <td>{{ project.installationOffice || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-account-tie</v-icon> {{ $t('project.details.manager') }}:
                    </td>
                    <td>{{ project.projectManager || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-clock-start</v-icon> {{ $t('project.details.start') }}:
                    </td>
                    <td>{{ project.productionStart || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-clock-end</v-icon> {{ $t('project.details.time') }}:
                    </td>
                    <td>{{ project.buildTime || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-wall</v-icon> {{ $t('project.details.system') }}:
                    </td>
                    <td>{{ project.constructionSystem || '-' }}</td>
                </tr>
                <tr>
                    <td>
                        <v-icon class="mr-2">mdi-heating-coil</v-icon> {{ $t('project.details.heat') }}:
                    </td>
                    <td>{{ project.heatGeneration || '-' }}</td>
                </tr>
                </tbody>
            </v-simple-table>

            <div class="d-flex justify-end">
                <v-btn depressed color="primary" @click.native="$router.push(`/project/form/${$store.project._id}`)">
                    <v-icon left>mdi-pencil</v-icon> {{ $t('general.edit') }}
                </v-btn>
            </div>
        </v-flex>
    </div>
</template>

<script>
import ProjectInterface from '@/services/ProjectInterface';

export default {
    data() {
        return {
            project: {},
            statusColor: {
                'new': 'blue-grey lighten-4',
                'in progress': 'lime lighten-4',
                'done': 'teal lighten-4'
            },
            statuses: [
                {text: this.$t('project.status.new'), value: 'new'},
                {text: this.$t('project.status.progress'), value: 'in progress'},
                {text: this.$t('project.status.done'), value: 'done'}
            ],
            ifcFile: null
        };
    },
    async created() {
        this.project = await ProjectInterface.getProjectById(this.$route.params.id);
        this.ifcFile = this.project.files[this.project.activeFile];
    },
    methods: {
        async onFileChange() {
            this.project.activeFile = this.project.files.findIndex(file => file.name === this.ifcFile);
            await ProjectInterface.changeProjectFile(this.project._id, this.project.activeFile);
        },
        getStatus() {
            if (this.project.status) return this.statuses.find(s => s.value === this.project.status).text;
        }
    }
}
</script>

<style scoped lang="scss">
tbody {
    tr {
        td {
            width: 50%;
        }

        &:hover {
            background-color: transparent !important;
        }
    }
}
</style>
