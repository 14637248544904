<template>
    <div>
        <v-simple-table dense>
            <thead>
                <tr :class="rowClasses['HEADING']">
                    <td>{{ $t('offer.preview.stabu') }}</td>
                    <td>{{ $t('offer.preview.name') }}</td>
                    <td>{{ $t('offer.preview.quantity') }}</td>
                    <td>{{ $t('offer.preview.unit') }}</td>
                    <td>{{ $t('offer.preview.rate') }}</td>
                    <td>{{ $t('offer.preview.hours') }}</td>
                    <td>{{ $t('offer.preview.material') }}</td>
                    <td>{{ $t('offer.preview.subcontractor') }}</td>
                    <td>{{ $t('offer.preview.equipment') }}</td>
                    <td>{{ $t('offer.preview.total') }}</td>
                    <td v-if="editable"></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in tableRows" :key="index"
                    :class="getRowClasses(row)"
                    @click="selectRow(row)">
                    <td>
                        <input v-if="row.type === 'PARAGRAPH' && editable"
                               v-model="row.stabuCode"
                               @change="rowChanged(row)">
                        <span v-else>{{ row.stabuCode }}</span>
                    </td>
                    <td>
                        <input v-if="(row.type === 'PARAGRAPH' || row.type === 'ITEM') && editable"
                               v-model="row.name"
                               @change="rowChanged(row)">
                        <span v-else>{{ row.name }}</span>
                    </td>
                    <td>
                        <input type="number" min="1"
                               v-if="row.type === 'ITEM' && editable"
                               v-model="row.quantity"
                               @change="rowChanged(row)">
                        <span v-else>{{ row.quantity }}</span>
                    </td>
                    <td>
                        <select v-model="row.unit"
                                :disabled="row.type !== 'ITEM' || !editable"
                                @change="rowChanged(row)">
                            <option disabled value="">Please Select</option>
                            <option value="METERS">M</option>
                            <option value="SQUARE_METERS">M2</option>
                            <option value="CUBIC_METERS">M3</option>
                            <option value="PIECE">PC</option>
                        </select>
                    </td>
                    <td class="pa-0">
                        <price-input v-model="row.rate"
                                     :editable="row.type === 'ITEM' && editable"
                                     @change="rowChanged(row)">
                        </price-input>
                    </td>
                    <td>
                        <input type="number" min="0"
                               v-if="row.type === 'ITEM' && editable"
                               v-model="row.hours"
                               @change="rowChanged(row)">
                        <span v-else>{{ row.hours }}</span>
                    </td>
                    <td class="pa-0">
                        <price-input v-model="row.material"
                                     :editable="row.type === 'ITEM' && editable"
                                     @change="rowChanged(row)">
                        </price-input>
                    </td>
                    <td class="pa-0">
                        <price-input v-model="row.subcontractor"
                                     :editable="row.type === 'ITEM' && editable"
                                     @change="rowChanged(row)">
                        </price-input>
                    </td>
                    <td class="pa-0">
                        <price-input v-model="row.equipment"
                                     :editable="row.type === 'ITEM' && editable"
                                     @change="rowChanged(row)">
                        </price-input>
                    </td>
                    <td>
                        <price :value="row.total"></price>
                    </td>
                    <td v-if="editable">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title @click.prevent="deleteRow(row)">
                                        <v-icon class="mr-2">mdi-delete</v-icon>
                                        Delete <span class="text-lowercase">{{ row.type }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="row.type === 'SUBCHAPTER' || row.type === 'PARAGRAPH'">
                                    <v-list-item-title @click.prevent="addRow(row)">
                                        <v-icon class="mr-2">mdi-plus</v-icon>
                                        Add <span class="text-lowercase">{{ getChildType(row.type) }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
                <tr :class="rowClasses['HEADING']">
                    <td></td>
                    <td colspan="8">TOTAL excluding storage</td>
                    <td><price :value="grandTotal"></price></td>
                    <td v-if="editable"></td>
                </tr>
            </tbody>
        </v-simple-table>

        <ConfirmationModal ref="confirmDelete"></ConfirmationModal>
    </div>
</template>

<script>
import OfferInterface from '@/services/OfferInterface';
import Price from '@/components/Property/Price.vue';
import PriceInput from '@/pages/Offer/PriceInput.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
    props: ['value', 'editable'],
    data() {
        return {
            projectId: this.$store.project._id,
            rowClasses: {
                'HEADING': 'red white--text font-weight-bold overline arrow-cursor',
                'CHAPTER': 'grey hand-cursor',
                'SUBCHAPTER': 'amber lighten-4 arrow-cursor',
                'PARAGRAPH': 'light-blue lighten-4 arrow-cursor',
                'ITEM': 'arrow-cursor'
            },
            tableRows: [],
            selectedRow: null,
            grandTotal: 0
        };
    },
    created() {
        if (this.value) this.generateRows();
    },
    watch: {
        value: function (val) {
            if (val) this.generateRows();
        }
    },
    methods: {
        getRowClasses(_row) {
            let classes = this.rowClasses[_row.type];

            if (_row.type === 'CHAPTER') {
                if (!this.selectedRow || _row.stabuCode === this.selectedRow) {
                    classes += ' lighten-3';
                } else {
                    classes += ' lighten-1';
                }
            }

            return classes;
        },
        getChildType(type) {
            if (type === 'CHAPTER') return 'SUBCHAPTER';
            if (type === 'SUBCHAPTER') return 'PARAGRAPH';
            if (type === 'PARAGRAPH') return 'ITEM';
        },
        getUnitLabel(unit) {
            if (unit === 'METERS') return 'M';
            if (unit === 'SQUARE_METERS') return 'M2';
            if (unit === 'CUBIC_METERS') return 'M3';
            if (unit === 'PIECE') return 'PC';
        },
        selectRow(_row) {
            if (_row.type !== 'CHAPTER') return;

            this.selectedRow = _row.stabuCode === this.selectedRow ? null : _row.stabuCode;
            this.generateRows();
        },
        rowChanged(row) {
            row.edited = true;
            const valid = this.grandTotal > 0;
            this.$parent.tableChanged(valid);
            this.refreshData();
        },
        async addRow(_row) {
            const split = _row.rowId.split('-');

            // add paragraph
            if (_row.type === 'SUBCHAPTER') {
                const c = +split[0];
                const s = +split[1];

                this.value.rows[c].subchapters[s].paragraphs.push({
                    stabuCode: _row.stabuCode,
                    name: 'NEW PARAGRAPH',
                    type: 'PARAGRAPH',
                    items: []
                });
            }

            // add item
            if (_row.type === 'PARAGRAPH') {
                const c = +split[0];
                const s = +split[1];
                const p = +split[2];

                this.value.rows[c].subchapters[s].paragraphs[p].items.push({
                    name: 'NEW ITEM',
                    type: 'ITEM',
                    unit: 'PIECE'
                });
            }

            this.generateRows();
        },
        async deleteRow(_row) {
            const deleteConfirmed = await this.$refs.confirmDelete.open(
                `Delete ${_row.type.toLowerCase()}`,
                `Are you sure you want to delete ${_row.name}?`,
                'Delete',
                'mdi-delete',
            );

            if (deleteConfirmed) {
                const split = _row.rowId.split('-');

                // delete chapter
                if (_row.type === 'CHAPTER') {
                    const c = +split[0];

                    this.value.rows.splice(c, 1);
                }

                // delete subchapter
                if (_row.type === 'SUBCHAPTER') {
                    const c = +split[0];
                    const s = +split[1];

                    this.value.rows[c].subchapters.splice(s, 1);
                }

                // delete paragraph
                if (_row.type === 'PARAGRAPH') {
                    const c = +split[0];
                    const s = +split[1];
                    const p = +split[2];

                    this.value.rows[c].subchapters[s].paragraphs.splice(p, 1);
                }

                // delete item
                if (_row.type === 'ITEM') {
                    const c = +split[0];
                    const s = +split[1];
                    const p = +split[2];
                    const i = +split[3];

                    this.value.rows[c].subchapters[s].paragraphs[p].items.splice(i, 1);
                }

                this.refreshData();
            }
        },
        async refreshData() {
            this.value.rows = await OfferInterface.preview(this.projectId, {offer: this.value.rows});
            this.generateRows();
        },
        generateRows() {
            this.tableRows = [];
            this.grandTotal = 0;

            // chapters
            this.value.rows.forEach((chapter, c) => {
                if (chapter.total > 0) this.grandTotal += chapter.total;
                chapter.rowId = `${c}`;
                this.tableRows.push(chapter);

                // subchapters
                if (chapter.stabuCode === this.selectedRow) {
                    chapter.subchapters.forEach((subchapter, s) => {
                        subchapter.rowId = `${c}-${s}`;
                        this.tableRows.push(subchapter);

                        // paragraphs
                        subchapter.paragraphs.forEach((paragraph, p) => {
                            paragraph.rowId = `${c}-${s}-${p}`;
                            this.tableRows.push(paragraph);

                            // items
                            paragraph.items.forEach((item, i) => {
                                item.rowId = `${c}-${s}-${p}-${i}`;
                                this.tableRows.push(item);
                            });
                        });
                    });
                }
            });
        }
    },
    components: {
        Price,
        PriceInput,
        ConfirmationModal
    }
};
</script>

<style scoped lang="scss">
.hand-cursor {
    cursor: pointer;
}

.arrow-cursor {
    cursor: default;
}

td:not(:last-child) {
    border-right: thin solid rgba(0, 0, 0, 0.12);
}

td:has(input) {
    padding: 0 !important;

    input {
        width: 100%;
        height: 100%;
        padding-left: 16px;
    }
}

td:has(select) {
    padding: 0 !important;

    select {
        width: 100%;
        height: 100%;
        padding-left: 16px;
    }
}

.v-list-item__title {
    cursor: pointer;
}
</style>
