<template>
    <div>
        <div class="pa-16">
            <div class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6
                        d-flex
                        justify-space-between
                        align-item-center">
                <div class="align-center">
                    <v-icon x-large left>mdi-database</v-icon>
                    {{ $t('prices.list.title') }}
                </div>
                <div>
                    <v-btn depressed
                           class="mr-4 text-button"
                           block
                           type="submit"
                           color="primary"
                           to="/data/form">
                        <v-icon left>mdi-database-plus</v-icon>
                        {{ $t('prices.list.add') }}
                    </v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="pt-8">
                <v-card outlined>
                    <v-simple-table v-model="components" class="text-no-wrap">
                        <thead>
                        <tr>
                            <th>{{ $t('prices.list.name') }}</th>
                            <th>{{ $t('prices.list.tag') }}</th>
                            <th>{{ $t('prices.list.stabu') }}</th>
                            <th>{{ $t('prices.list.unit') }}</th>
                            <th>{{ $t('prices.list.price') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(component, index) in components" :key="index">
                            <td class="name">{{ component.name }}</td>
                            <td>{{ component.tag }}</td>
                            <td>{{ component.stabuCode || '-' }}</td>
                            <td>
                                <v-chip small class="text-capitalize font-weight-medium">
                                    {{ getUnitLabel(component.unit) }}
                                </v-chip>
                            </td>
                            <td><span>&euro;</span> {{ component.unitPrice }}</td>
                            <td class="text-right">
                                <v-menu offset-y bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="dark"
                                               icon
                                               v-bind="attrs"
                                               v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link :to="'/data/form/' + component._id">
                                            <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.prevent="deleteComponent(component._id, component.name)">
                                            <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </div>
            <ConfirmationModal ref="confirm"></ConfirmationModal>
        </div>
    </div>
</template>

<script>
import ConfirmationModal from '../../components/ConfirmationModal';
import ComponentInterface from '../../services/ComponentInterface.js';

export default {
    data() {
        return {
            components: []
        };
    },
    created() {
        this.$store.components = null;
        this.refresh();
    },
    methods: {
        refresh() {
            ComponentInterface.getAllComponents()
                .then(components => this.components = components);
        },
        async deleteComponent(id, name) {
            if (await this.$refs.confirm.open(
                'Delete component',
                `Are you sure you want to delete ${name}?`,
                'Delete',
                'mdi-delete'
            )) {
                await ComponentInterface.removeComponent(id);
                this.refresh();
            }
        },
        getUnitLabel(unit) {
            let label;
            if (unit === 'METERS') label = this.$t('general.meters');
            if (unit === 'SQUARE_METERS') label = this.$t('general.square');
            if (unit === 'CUBIC_METERS') label = this.$t('general.cubic');
            if (unit === 'PIECE') label = this.$t('general.piece');
            return label;
        }
    },
    components: {
        ConfirmationModal
    }
}
</script>

<style scoped>
.name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
