<template>
    <div class="holder">
        <span v-if="value || changed">€</span>
        <input type="number" min="0"
               v-if="editable"
               :value="value"
               :class="{ hidden: !focused }"
               @input="onInput($event)"
               @change="onChange($event)"
               @focus="onFocus"
               @blur="onBlur">
        <div v-if="!focused">{{
                value ? new Intl.NumberFormat($i18n.locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(value) : ''
            }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'price-input',
    props: ['value', 'editable'],
    data() {
        return {
            changed: false,
            focused: false
        }
    },
    methods: {
        onInput(e) {
            this.changed = !!e.target.value;
            this.$emit('input', e.target.value);
        },
        onChange(e) {
            this.$emit('change', e);
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        }
    }
};
</script>

<style scoped lang="scss">
div.holder {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;

    span {
        position: absolute;
        top: 8px;
        left: 16px;
        z-index: 2;
        pointer-events: none;
    }

    input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        z-index: 1;
    }

    div {
        position: absolute;
        top: 8px;
        left: 30px;
        z-index: 0;
        pointer-events: none;
    }
}

.hidden {
    opacity: 0;
}
</style>
