import Layout from '../layout/Layout.vue';
import ProjectList from '../pages/Project/ProjectList.vue';
import AddEditProject from '../pages/Project/AddEditProject.vue';
import ProjectDetails from '../pages/Project/ProjectDetails.vue';
import Project3DViewer from '../pages/Project/3DViewer.vue';
import PriceList from '../pages/Prices/PriceList.vue';
import ComponentForm from '../pages/Prices/AddEditPrice.vue';
import OfferOverview from '../pages/Offer/OfferList.vue';
import OfferDetails from '../pages/Offer/OfferDetails.vue';
import AddEditOffer from '../pages/Offer/AddEditOffer.vue';
import Register from '../pages/Auth/Register.vue';
import Login from '../pages/Auth/Login.vue';
import ForgotPass from '../pages/Auth/ForgotPass.vue';
import ResetPass from '../pages/Auth/ResetPass.vue';
import ApiResponse from '../pages/ApiResponse';
import Organization from '../pages/Organization/Details.vue';
import UserProfile from '../pages/UserProfile/Details.vue';
import UserList from '../pages/Users/UserList.vue';
import InviteUser from '../pages/Users/InviteUser.vue';

import AuthInterface from '../services/AuthInterface.js';

export default [
    {
        path: '/register',
        name: 'Register',
        component: Register,
        props: true,
        meta: {
            requiresAuth: false,
            hideForAuth: true
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true,
        meta: {
            requiresAuth: false,
            hideForAuth: true
        },
    },
    {
        path: '/forgot',
        name: 'Forgot Password',
        component: ForgotPass,
        meta: {
            requiresAuth: false,
            hideForAuth: true
        },
    },
    {
        path: '/reset',
        name: 'Reset Password',
        component: ResetPass,
        meta: {
            requiresAuth: false,
            hideForAuth: true
        },
    },
    {
        path: '/verify',
        name: 'Verify',
        beforeEnter: (to, from, next) => {
            const { token, invitationToken, confirmNewEmailToken, email, organization } = to.query;
            const redirectRoute = invitationToken ? {path: '/register', name: 'Register'} : {path: '/login', name: 'Login'};

            const confirmationToken = token || invitationToken || confirmNewEmailToken;
            const confirmNewEmail = confirmNewEmailToken && confirmNewEmailToken.length > 0;

            const redirectParams = {
                verified: true,
                email: email,
                organization: organization,
                message: confirmNewEmail ? 'Your new email address has been successfully confirmed!' : 'Your account has been successfully activated!',
            }

            AuthInterface.confirmEmail({token: confirmationToken, confirmNewEmail})
                .then((response) => {           
                    if (response.success) {
                        next({path: redirectRoute.path, name: redirectRoute.name, params: redirectParams});
                    } else {
                        next({
                            path: '/response',
                            name: 'response',
                            params: {
                                    title: 'Something went wrong',
                                    icon: 'mdi-alert-octagon',
                                    success: false
                                }
                            }
                        );
                    }
                })
                .catch(() => {
                    next({path: '/response', name: 'response', params: { title: 'Something went wrong',success: false}});
                });
        },
        component: Login,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/response',
        name: 'response',
        component: ApiResponse,
        props: true,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/',
        component: Layout,
        redirect: '/projects',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'projects',
                name: 'Projects',
                component: ProjectList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'data',
                name: 'Supplier data',
                component: PriceList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'data/form',
                name: 'Supplier data form',
                component: ComponentForm,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'data/form/:id',
                name: 'Supplier data form (edit)',
                component: ComponentForm,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/form',
                name: 'Add project',
                component: AddEditProject,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/form/:id',
                name: 'Update project',
                component: AddEditProject,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id',
                name: 'Project details',
                component: ProjectDetails,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id/3D',
                name: 'Project 3D viewer',
                component: Project3DViewer,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id/offers',
                name: 'Offers overview',
                component: OfferOverview,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id/offers/add',
                name: 'New offer',
                component: AddEditOffer,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id/offers/:offerId',
                name: 'Offer',
                component: OfferDetails,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'project/:id/offers/:offerId/edit',
                name: 'Edit offer',
                component: AddEditOffer,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'project/:id/offers/:offerId/edit/:version',
                name: 'Edit offer',
                component: AddEditOffer,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'organization',
                name: 'Organization',
                component: Organization,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'profile',
                name: 'My profile',
                component: UserProfile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'users',
                name: 'Users',
                component: UserList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'users/form',
                name: 'Invite new users',
                component: InviteUser,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'users/form/:id',
                name: 'Resend invitation',
                component: InviteUser,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    }
];
