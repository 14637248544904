<template>
    <div>
        <v-snackbar v-model="error" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <div class="pa-16">
            <div v-if="!id" class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6">
                <v-icon x-large left>mdi-database-plus</v-icon>
                {{ $t('prices.add.add') }}
            </div>
            <div v-else class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6">
                <v-icon x-large left>mdi-database-edit</v-icon>
                {{ $t('prices.add.edit') }}
            </div>

            <v-divider></v-divider>

            <v-flex md12 lg6 class="mt-10">
                <v-form v-model="valid" @submit="submit">
                    <v-text-field
                        outlined
                        v-model="component.name"
                        prepend-icon="mdi-database"
                        :label="$t('prices.add.name') + '*'"
                        :rules="nameRules"
                        @input="onChange"
                        required
                    ></v-text-field>
                    <v-text-field
                        outlined
                        v-model="component.tag"
                        :rules="tagRules"
                        prepend-icon="mdi-tag"
                        :label="$t('prices.add.tag') + '*'"
                        @input="onChange"
                        required
                    ></v-text-field>
                    <stabu-code-input v-if="!id || component.stabuCode"
                                      v-model="component.stabuCode"
                                      @onChange="onChange">
                    </stabu-code-input>
                    <v-select
                        outlined
                        :items="unitTypes"
                        item-text="label"
                        item-value="unit"
                        prepend-icon="mdi-tape-measure"
                        :label="$t('prices.add.unit') + '*'"
                        v-model="component.unit"
                        :rules="validationRules"
                        @input="onChange"
                    ></v-select>
                    <v-text-field
                        outlined
                        v-model="component.unitPrice"
                        :rules="priceRules"
                        prepend-icon="mdi-currency-eur"
                        :label="$t('prices.add.price') + '*'"
                        @input="onChange"
                        required
                    ></v-text-field>
                    <div class="d-flex justify-end mt-4">
                        <v-btn color="secondary"
                               outlined
                               class="mr-4"
                               @click="$router.push(`/data`)">
                            <v-icon left>mdi-close</v-icon> {{ $t('general.cancel') }}
                        </v-btn>
                        <v-btn color="primary"
                               depressed
                               type="submit"
                               :disabled="!valid || !changed"
                               :loading="loading">
                            <v-icon left>mdi-content-save</v-icon> {{ $t('general.save') }}
                        </v-btn>
                    </div>
                </v-form>
            </v-flex>
        </div>
    </div>
</template>

<script>
import ComponentInterface from '../../services/ComponentInterface';
import StabuCodeInput from '@/components/Prices/StabuCodeInput.vue';
import {STABU_CODE_REGEX, TAG_REGEX} from '@/constants';

export default {
    components: {
        StabuCodeInput
    },
    data() {
        return {
            id: null,
            component: {
                name: '',
                tag: '',
                stabuCode: '',
                unit: null,
                unitPrice: null
            },
            oldComponent: null,
            allComponents: [],
            error: null,
            valid: false,
            changed: false,
            loading: false,
            validationRules: [(v) => !!v || 'This field is required'],
            tagRules: [
                v => !!v || 'This field is required',
                v => TAG_REGEX.test(v) || 'Invalid tag',
            ],
            nameRules: [
                v => !!v || 'This field is required',
                v => v.length >= 5 || 'Minimum 5 characters required',
                v => v.length <= 100 || 'Maximum 100 characters allowed',
                v => !this.allComponents.some(component => component._id !== this.id && component.name === v) || 'Supplier data with this name already exists'
            ],
            priceRules: [
                v => !!v || 'This field is required',
                v => !isNaN(v) || 'Invalid price',
            ],
            unitTypes: [
                {label: 'Meters', unit: 'METERS'},
                {label: 'Square Meters', unit: 'SQUARE_METERS'},
                {label: 'Cubic Meters', unit: 'CUBIC_METERS'},
                {label: 'Piece', unit: 'PIECE'}
            ]
        };
    },
    async created() {
        const {id} = this.$route.params;

        if (id) {
            this.id = id;
            await ComponentInterface.getComponent(id)
                .then(component => {
                    this.component = component;
                    this.oldComponent = {...component};
                })
                .catch(error => this.error = error);
        }

        this.allComponents = await ComponentInterface.getAllComponents();
    },
    methods: {
        submit(event) {
            event.preventDefault();
            this.loading = true;

            if (this.id) {
                ComponentInterface.updateComponent(this.id, this.component)
                    .then(() => this.$router.push({path: '/data'}))
                    .catch(error => this.error = error)
                    .finally(() => this.loading = false);
            } else {
                ComponentInterface.createComponent(this.component)
                    .then(() => this.$router.push({path: '/data'}))
                    .catch(error => this.error = error)
                    .finally(() => this.loading = false);
            }
        },
        onChange() {
            const isStabuValid = STABU_CODE_REGEX.test(this.component.stabuCode);
            this.valid = isStabuValid ? this.valid : false;

            if (!this.id) return this.changed = true;

            this.changed = false;

            for (const property in this.component) {
                if (this.component[property] !== this.oldComponent[property]) {
                    this.changed = true;
                }
            }
        }
    }
};
</script>
