<template>
    <div>
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <div class="pa-16">
            <div class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6
                        d-flex
                        justify-space-between
                        align-item-center">
                <div class="align-center">
                    <v-icon x-large left>mdi-folder-multiple</v-icon> {{ $t('project.list.title') }}
                </div>
                <div>
                    <v-btn depressed
                           class="mr-4 text-button"
                           block
                           type="submit"
                           color="primary"
                           to="/project/form">
                        <v-icon left>mdi-folder-plus</v-icon> {{ $t('project.list.add') }}
                    </v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="pt-8">
                <v-card outlined>
                    <v-simple-table v-model="projects" class="text-no-wrap">
                        <thead>
                            <tr>
                                <th>{{ $t('project.list.name') }}</th>
                                <th>{{ $t('project.list.description') }}</th>
                                <th>{{ $t('project.list.architect') }}</th>
                                <th>{{ $t('project.list.constructor') }}</th>
                                <th>{{ $t('project.list.location') }}</th>
                                <th class="text-center">{{ $t('project.list.created') }}</th>
                                <th class="text-center">{{ $t('project.list.status') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(project, index) in projects" :key="index">
                                <td class="font-weight-medium">
                                    <router-link :to="`/project/${project._id}`">
                                        {{ project.name || '-' }}
                                    </router-link>
                                </td>
                                <td class="description">
                                    {{ project.description || '-' }}
                                </td>
                                <td>
                                    {{ project.architect || '-' }}
                                </td>
                                <td>
                                    {{ project.constructionCompany || '-' }}
                                </td>
                                <td>
                                    {{ project.projectLocation || '-' }}
                                </td>
                                <td class="text-center">
                                    {{ project.created | moment('calendar') }}
                                </td>
                                <td class="text-center">
                                    <v-chip :class="statusColor[project.status]"
                                            small
                                            class="text-capitalize font-weight-medium">
                                        {{ project.status || '-' }}
                                    </v-chip>
                                </td>
                                <td class="text-right">
                                    <v-menu offset-y bottom left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="dark"
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                link
                                                :to="'/project/form/' + project._id"
                                            >
                                                <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click.prevent="deleteProject(project)">
                                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </div>
            <ConfirmationModal ref="confirmDeleteProject"></ConfirmationModal>
        </div>
    </div>
</template>

<script>
import ProjectInterface from '../../services/ProjectInterface.js';
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
    data() {
        return {
            projects: [],
            statusColor: {
                'new': 'blue-grey lighten-4',
                'in progress': 'lime lighten-4',
                'done': 'teal lighten-4'
            },
            error: null,
            showError: false
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            ProjectInterface.getAllProjects().then(projects => this.projects = projects);
        },
        async deleteProject(project) {
            const deleteConfirmed = await this.$refs.confirmDeleteProject.open(
                'Delete Project',
                `Are you sure you want to delete ${project.name}?`,
                'Delete',
                'mdi-delete',
            );

            if (deleteConfirmed) {
                ProjectInterface.deleteProject(project._id)
                    .then(response => {
                        if (response.error) throw new Error(response.error);
                        this.refresh();
                    })
                    .catch(error => {
                        this.error = error;
                        this.showError = true;
                    });
            }
        },
    },
    components: {
        ConfirmationModal
    }
};
</script>

<style scoped>
.description {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
