<template>
    <div>
        <v-snackbar v-model="error" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <div class="pa-16">
            <div v-if="!id"
                class="pb-8 font-weight-bold text-uppercase text-h6">
                <v-icon x-large left>mdi-account-plus</v-icon> Invite new users
            </div>
            <div v-else
                class="pb-8 font-weight-bold text-uppercase text-h6">
                <v-icon x-large left>mdi-account-plus</v-icon> Resend invitation
            </div>

            <v-divider></v-divider>

            <v-flex md12 lg6 class="mt-10">
                <v-form v-model="valid" @submit="submit">
                    <div class="mb-4 grey--text text--darken-1">
                        Add multiple emails by pressing Enter or Tab.
                    </div>
                    <v-combobox
                        v-if="!id"
                        v-model="chips"
                        small-chips
                        clearable
                        label="Email addresses"
                        prepend-icon="mdi-email-multiple"
                        outlined
                        disable-lookup
                        multiple
                        required
                        :rules="emailRules"
                    >
                        <template
                            v-slot:selection="{ attrs, item, select, selected }"
                        >
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="remove(item)"
                            >
                                <strong>{{ item }}</strong>
                            </v-chip>
                        </template>
                    </v-combobox>
                    <v-text-field
                        v-else
                        outlined
                        v-model="email"
                        :rules="resendEmailRules"
                        prepend-icon="mdi-email"
                        label="Email"
                        required
                    >
                    </v-text-field>
                    <div class="d-flex justify-end mt-4">
                        <v-btn color="secondary"
                               outlined
                               class="mr-4"
                               @click="$router.push(`/users`)">
                            <v-icon left>mdi-close</v-icon>
                            Cancel
                        </v-btn>
                        <v-btn v-if="!id"
                               color="primary"
                               depressed
                               type="submit"
                               @click="submit"
                               :disabled="!chips.length || !valid"
                        >
                            <v-icon left>mdi-account-plus</v-icon>
                            Invite
                        </v-btn>
                        <v-btn v-else
                               color="primary"
                               depressed
                               type="submit"
                               @click="submit"
                               :disabled="!valid"
                        >
                            <v-icon left>mdi-account-plus</v-icon>
                            Resend
                        </v-btn>
                    </div>
                </v-form>
            </v-flex>
        </div>
    </div>
</template>

<script>
import {EMAIL_REGEX} from '@/constants';
import UserInterface from '@/services/UserInterface';

export default {
    data() {
        return {
            valid: false,
            dialog: false,
            chips: [],
            id: null,
            user: null,
            email: '',
            emailRules: [
                v => !!v || 'At least one email is required',
                v => {
                    for (let email of v) {
                        if (!EMAIL_REGEX.test(email)) {
                            return 'Emails must be valid';
                        }
                    }
                    return true;
                }
            ],
            resendEmailRules: [
                v => !!v || 'Email is required',
                v => EMAIL_REGEX.test(v) || 'Email must be valid'
            ],
            error: null,
            showError: false,
            validationErrors: [],
        };

    },
    async created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.user = await UserInterface.getUser(this.id);
            this.email = this.user.email;
        }
    },
    methods: {
        open() {
            this.dialog = true;
        },

        close() {
            this.chips = [];
            this.validationErrors = [];
            this.dialog = false;
        },

        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1);
            this.chips = [...this.chips];
        },

        submit(event) {
            event.preventDefault();

            if (!this.email) {
                this.validationErrors = [];

                this.chips.forEach((e) => {
                    EMAIL_REGEX.test(e.toLowerCase()) ||
                    this.validationErrors.push(
                        `${e} is not a valid email address`
                    );
                });

                if (this.validationErrors.length) return;

                UserInterface.inviteUsers({emails: this.chips})
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        } else {
                            this.$router.push(`/users`)
                        }
                    })
                    .catch((e) => {
                        this.error = e;
                        this.showError = true;
                    });
            } else {
                UserInterface.resendInvitation(this.user._id, {
                    email: this.email.toLowerCase(),
                })
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        } else {
                            this.$router.push(`/users`)
                        }
                    })
                    .catch((e) => {
                        this.error = e;
                        this.showError = true;
                    });
            }
        },
    },
};
</script>
