<template>
    <div>
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>

        <v-card v-if="offerTable && project">
            <div class="no-print">
                <div class="d-flex justify-space-between" v-if="offerTable">
                    <div>
                        <v-card-title class="nowrap">{{ offerTable.name }}</v-card-title>
                        <v-card-subtitle>{{ offerTable.description }}</v-card-subtitle>
                    </div>
                    <div class="d-flex pa-4">
                        <span class="text-subtitle pt-1 pr-4">Version</span>
                        <v-select
                            class="pa-0 ma-0 versions-selector"
                            :items="offer.versions.map(v => offer.versions.indexOf(v) + 1).reverse()"
                            :value="selectedVersion"
                            v-model="selectedVersion"
                            @change="switchVersions"
                        ></v-select>
                        <v-btn
                            class="ml-3"
                            depressed
                            color="secondary"
                            @click.prevent="editVersion"
                        >
                            <v-icon left>mdi-pencil</v-icon>Edit version
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            depressed
                            color="secondary"
                            @click.prevent="deleteVersion"
                        >
                            <v-icon left>mdi-delete</v-icon>Delete version
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            depressed
                            color="primary"
                            @click.prevent="newVersion"
                        >
                            <v-icon left>mdi-plus</v-icon>New version
                        </v-btn>

                        <v-btn @click="print"
                               outlined
                               color="secondary"
                               class="ml-3">
                            <v-icon left>mdi-printer</v-icon>
                            Print
                        </v-btn>
                    </div>
                </div>
            </div>
            <div>
                <v-row class="mt-n6">
                    <v-col class="pb-0">
                        <v-card-title>Client</v-card-title>
                        <v-simple-table class="mb-6 flex-fill">
                            <tbody>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-account</v-icon> Name
                                </td>
                                <td>{{ offerTable.clientName || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-email</v-icon> Email
                                </td>
                                <td><a :href="`mailto:${offerTable.clientEmail}`"> {{ offerTable.clientEmail || '-' }}</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-phone</v-icon> Phone
                                </td>
                                <td>{{ offerTable.clientPhone || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-map-marker</v-icon> Address
                                </td>
                                <td>{{ offerTable.clientAddress || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-city</v-icon> City
                                </td>
                                <td>{{ offerTable.clientCity || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-map-marker-radius</v-icon> Postcode
                                </td>
                                <td>{{ offerTable.clientPostcode || '-' }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>

                    <v-col class="pb-0">
                        <v-card-title>Project</v-card-title>
                        <div class="grey--text text--darken-2 text-overline text-uppercase pb-4 pl-4 nowrap">General information</div>
                        <v-simple-table class="flex-fill">
                            <tbody>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-identifier</v-icon> Identifier:
                                </td>
                                <td>{{ project._id }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-folder</v-icon> Name:
                                </td>
                                <td>{{ project.name }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-text-box</v-icon> Description:
                                </td>
                                <td>{{ project.description || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-calendar-month</v-icon> Creation date:
                                </td>
                                <td>{{ project.created | moment('calendar') }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-clipboard-check</v-icon> Status:
                                </td>
                                <td>
                                    <v-chip :class="statusColor[project.status]" small class="text-capitalize font-weight-medium">
                                        {{ project.status }}
                                    </v-chip>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>

                    <v-col class="ml-n6 pb-0">
                        <div class="grey--text text--darken-2 text-overline text-uppercase pb-4 pt-16 pl-4 nowrap">Additional information</div>
                        <v-simple-table class="flex-fill">
                            <tbody>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-folder</v-icon> Project number:
                                </td>
                                <td>{{ project.projectNumber || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-map-marker</v-icon> Project location:
                                </td>
                                <td>{{ project.projectLocation || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-ruler-square</v-icon> Architect:
                                </td>
                                <td>{{ project.architect || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-account-hard-hat</v-icon> Construction company:
                                </td>
                                <td>{{ project.constructionCompany || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-office-building</v-icon> Installation office:
                                </td>
                                <td>{{ project.installationOffice || '-' }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-col class="ml-n6 pb-0">
                        <v-simple-table class="flex-fill mt-16 pt-12">
                            <tbody>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-account-tie</v-icon> Project manager:
                                </td>
                                <td>{{ project.projectManager || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-clock-start</v-icon> Production start:
                                </td>
                                <td>{{ project.productionStart || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-clock-end</v-icon> Build time:
                                </td>
                                <td>{{ project.buildTime || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-wall</v-icon> Construction system:
                                </td>
                                <td>{{ project.constructionSystem || '-' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-icon class="mr-2">mdi-heating-coil</v-icon> Heat generation:
                                </td>
                                <td>{{ project.heatGeneration || '-' }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <preview-offer
                    class="print-table"
                    v-model="offerTable"
                ></preview-offer>
            </div>
            <preview-offer
                v-model="offerTable"
                class="no-print"
                :editable="false"
            ></preview-offer>
        </v-card>
        <ConfirmationModal ref="confirmDeleteVersion"></ConfirmationModal>
    </div>
</template>

<script>
import OfferInterface from '@/services/OfferInterface.js';
import PreviewOffer from './PreviewOffer.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import ProjectInterface from "@/services/ProjectInterface";

export default {
    components: {
        PreviewOffer,
        ConfirmationModal
    },
    data() {
        return {
            offer: null,
            offerTable: null,
            selectedVersion: '',
            project: this.$store.project,
            statusColor: {
                'new': 'blue-grey lighten-4',
                'in progress': 'lime lighten-4',
                'done': 'teal lighten-4'
            },
            error: null,
            showError: false
        };
    },
    async created() {
        await OfferInterface.getOffer(
            this.$store.project._id,
            this.$route.params.offerId
        ).then((offer) => {
            const offerVersions = offer.versions;
            this.offer = offer;
            this.offerTable = offerVersions[offerVersions.length - 1];
            return this.selectedVersion = offerVersions.length;
        });
    },
    methods: {
        print() {
            window.print();
        },
        switchVersions() {
            this.offerTable = this.offer.versions[this.selectedVersion - 1];
        },
        async deleteVersion() {
            if (this.offer.versions.length !== 1) {
                const deleteConfirmed = await this.$refs.confirmDeleteVersion.open(
                    'Delete Offer Version',
                    `Are you sure you want to delete this version?`,
                    'Delete',
                    'mdi-delete',
                );
                if (deleteConfirmed) {
                    this.offer.versions.splice(this.selectedVersion - 1, 1);
                    await OfferInterface.updateOffer(this.$store.project._id, this.offer._id, this.offer)
                        .then(response => {
                            if (response.error) throw new Error(response.error);
                            this.selectedVersion = this.offer.versions.length;
                            this.switchVersions();
                        }).catch(error => {
                            this.error = error.message;
                            this.showError = true;
                        })
                }
            } else {
                const deleteConfirmed = await this.$refs.confirmDeleteVersion.open(
                    'Delete Offer Version',
                    `Are you sure you want to delete this version? Warning: This is the last version and will delete the whole offer!`,
                    'Delete',
                    'mdi-delete',
                );
                if (deleteConfirmed) {
                    await OfferInterface.removeOffer(this.$store.project._id, this.offer._id)
                        .then(response => {
                            if (response.error) throw new Error(response.error);
                            this.$router.push('/project/' + this.$store.project._id + '/offers');
                        }).catch(error => {
                            this.error = error.message;
                            this.showError = true;
                        })
                }
            }
        },
        async newVersion() {
            const ifcFile = this.$store.project.files.findIndex(file => file.key === this.offer.file.key);
            if (ifcFile !== -1) {
                await ProjectInterface.changeProjectFile(this.$store.project._id, ifcFile);
                this.$router.push(`/project/${this.$store.project._id}/offers/${this.$route.params.offerId}/edit`);
            } else {
                this.error = 'IFC file not found';
                this.showError = true;
            }
        },
        async editVersion() {
            const ifcFile = this.$store.project.files.findIndex(file => file.key === this.offer.file.key);
            if (ifcFile !== -1) {
                await ProjectInterface.changeProjectFile(this.$store.project._id, ifcFile);
                this.$router.push(`/project/${this.$store.project._id}/offers/${this.$route.params.offerId}/edit/${this.selectedVersion-1}`);
            } else {
                this.error = 'IFC file not found';
                this.showError = true;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../public/print.css';

.versions-selector {
    width: 80px;
}

tbody {
    tr:hover {
        cursor: default;
        background-color: transparent !important;
    }
}

td, .nowrap {
    max-width: 80px;
    white-space: nowrap;
}
</style>
