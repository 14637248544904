import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import VueI18n from 'vue-i18n';
import Rollbar from 'rollbar';
import App from './App.vue';
import AuthInterface from './services/AuthInterface.js';

// router setup
import routes from './routes/routes';

// configure router
const router = new VueRouter({
    routes,
    mode: 'history',
    linkExactActiveClass: 'nav-item active',
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        const token = localStorage.getItem('token');
        AuthInterface.isLoggedIn({token})
            .then(response => {
                if (response.ok) {
                    next();
                } else {
                    next({name: 'Login'});
                }
            });
    } else if (to.meta.hideForAuth) {
        const token = localStorage.getItem('token');
        AuthInterface.isLoggedIn({token})
            .then(response => {
                if (response.ok) {
                    next({name: 'Projects'});
                } else {
                    next();
                }
            });
    } else {
        next();
    }
});

Vue.use(VueRouter);
Vue.use(VueMoment);
Vue.use(VueI18n);

import en from './i18n/en.js';
import nl from './i18n/nl.js';
import vuetify from './plugins/vuetify';

const i18n = new VueI18n({
    locale: localStorage.getItem('mooduul-language') || 'nl',
    messages: { nl, en },
});

Vue.prototype.$store = Vue.observable({project: null});

// Filters
import moment from 'moment';

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
    }
});

/* Error notifier */
if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging'
) {
    Vue.prototype.$rollbar = new Rollbar({
        accessToken: process.env.VUE_APP_ROLLBAR_KEY,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        addErrorContext: true,
        payload: {
            environment: process.env.NODE_ENV,
        },
    });

    Vue.config.errorHandler = (err, vm) => {
        vm.$rollbar.error(err);
    };

    window.onerror = (message) => {
        Vue.rollbar.error(message);
    };
}

/* eslint-disable no-new */
new Vue({
    el: '#app',
    i18n,
    router,
    vuetify,
    render: (h) => h(App),
});
