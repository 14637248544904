<template>
    <div>
        <div v-if="user && user.admin" class="pa-16">
            <div class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6
                        d-flex
                        justify-space-between
                        align-item-center">
                <div v-if="organization" class="align-center">
                    <v-icon x-large left>mdi-domain</v-icon> {{ currentOrganization.name }}
                </div>
            </div>
            <v-divider></v-divider>
            <v-snackbar v-model="showMessage" timeout="3000" :color="color">
                {{ message }}
            </v-snackbar>
            <div class="pt-8">
                <v-flex md12 lg6>
                    <v-form v-if="organization" v-model="valid" @submit="submit">
                        <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">{{ $t('organization.title') }}</div>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :label="$t('organization.name')"
                                    :rules="validationRules"
                                    v-model="organization.name"
                                    @input="onChange"
                                    outlined
                                    reuired
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    class="pa-0 ma-0 versions-selector"
                                    :items="countries"
                                    value="organization.country"
                                    v-model="organization.country"
                                    @input="onChange"
                                    :label="$t('organization.country')"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-end mt-4">
                            <v-btn
                                depressed
                                type="submit"
                                color="primary"
                                :disabled="!valid || !changed"
                                :loading="loading">
                                <v-icon left>mdi-content-save</v-icon> {{ $t('general.save') }}
                            </v-btn>
                        </div>
                    </v-form>
                </v-flex>
            </div>
        </div>
        <div class="red--text pa-10" v-if="user && !user.admin">
            {{ $t('organization.permission') }}
        </div>
    </div>
</template>

<script>
import countries from 'countries-list';
import OrganizationInterface from '../../services/OrganizationInterface.js';
import { ONLY_LETTERS_REGEX } from '../../constants.js';

export default {
    data() {
        const countryCodes = Object.keys(countries.countries);
        const countryNames = countryCodes.map(
            (code) => countries.countries[code].name
        );
        return {
            countries: countryNames.sort(),
            changed: false,
            valid: false,
            loading: false,
            message: null,
            color: null,
            showMessage: false,
            validationRules: [
                (v) => !!v.trim() || 'This field is required',
                (v) => ONLY_LETTERS_REGEX.test(v) || 'Only letters allowed',
            ],
            user: null,
            organization: null,
            currentOrganization: null,
        };
    },
    methods: {
        onChange() {
            this.changed =
                this.organization.name !== this.currentOrganization.name ||
                this.organization.country !== this.currentOrganization.country;
        },
        submit(event) {
            event.preventDefault();

            this.loading = true;

            OrganizationInterface.updateOrganization(this.organization._id, {
                ...this.organization,
            })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    } else {
                        this.message = 'Your changes were saved.';
                        this.showMessage = true;
                        this.color = 'success';
                        this.currentOrganization = { ...this.organization };
                        this.changed = false;
                    }
                })
                .catch((e) => {
                    this.message = e;
                    this.showMessage = true;
                    this.color = 'error';
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    created() {
        this.user = this.$store.currentUser;
        this.organization = { ...this.$store.currentOrganization };
        this.currentOrganization = { ...this.$store.currentOrganization };
    },
};
</script>
