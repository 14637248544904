<template>
    <span v-if="value || value === 0">
        {{
            new Intl.NumberFormat($i18n.locale, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value)
        }}
    </span>
</template>

<script>
export default {
    name: 'price',
    props: ['value'],
};
</script>
