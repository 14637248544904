class ProjectInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static createProject(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project`, {
            method: 'POST',
            headers: this.getAuthHeader(),
            body
        }).then(response => response.json());
    }

    static getFileByProjectId(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${id}/file`, {
            method: 'GET',
            headers: this.getAuthHeader()
        }).then(response => response.text());
    }

    static updateProject(id, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${id}`, {
            method: 'PUT',
            headers: this.getAuthHeader(),
            body
        }).then((response) => response.json());
    }

    static getAllProjects() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project`, {
            method: 'GET',
            headers: this.getAuthHeader()
        }).then(response => response.json());
    }

    static getProjectById(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${id}`, {
            method: 'GET',
            headers: this.getAuthHeader()
        }).then(response => response.json());
    }

    static deleteProject(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${id}`, {
            method: 'DELETE',
            headers: this.getAuthHeader()
        }).then(response => response.json());
    }

    static changeProjectFile(id, index) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${id}/file/${index}`, {
            method: 'PUT',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }
}

export default ProjectInterface;
