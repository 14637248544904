class OfferInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getOffers(projectId) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static getOffer(projectId, offerId) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer/${offerId}`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static createOffer(projectId, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static preview(projectId, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer/preview`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static updateOffer(projectId, offerId, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer/${offerId}`, {
            method: 'PUT',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static removeOffer(projectId, offerId) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/project/${projectId}/offer/${offerId}`, {
            method: 'DELETE',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }
}

export default OfferInterface;
