<template>
    <div>
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <v-card max-width="400" class="mx-auto mt-12" outlined>
            <div class="d-flex py-12">
                <v-img
                    contain
                    alt="Mooduul"
                    src="@/assets/logo.svg"
                    transition="scale-transition"
                    width="410"
                    max-height="140"
                    margin="mx-auto"
                />
            </div>
            <v-card-text class="px-8 py-12 pt-0">
                <v-form v-model="valid" @submit="submit">
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="Email"
                        required
                        outlined
                        :disabled="loading"
                    ></v-text-field>
                    <v-btn
                        class="mr-4"
                        block
                        type="submit"
                        color="primary"
                        :disabled="!valid"
                        :loading="loading"
                        depressed
                    >
                        Forgot password
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import AuthInterface from '@/services/AuthInterface.js';
import { EMAIL_REGEX } from '../../constants.js';

export default {
    name: 'ForgotPass',
    data() {
        return {
            valid: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) =>
                    EMAIL_REGEX.test(v.toLowerCase()) || 'E-mail must be valid',
            ],
            email: '',
            error: null,
            showError: false,
            loading: false,
            linkSent: false,
        };
    },
    methods: {
        submit(event) {
            event.preventDefault();
            this.loading = true;

            AuthInterface.forgotPass({ email: this.email })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    }

                    if (response.success) {
                        this.$router.push({
                            path: '/response',
                            name: 'response',
                            params: {
                                title: 'Check your inbox!',
                                message:
                                    'We sent you an email with instructions on how to reset your password.',
                                icon: 'mdi-email-check',
                                success: true,
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.error = error;
                    this.showError = true;
                    this.loading = false;
                });
        },
    },
};
</script>
