<template>
    <div class="d-flex fill-height">
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <v-card class="login" outlined>
            <div class="d-flex py-12">
                <v-img
                    contain
                    alt="Mooduul"
                    src="@/assets/logo.svg"
                    transition="scale-transition"
                    width="410"
                    max-height="140"
                    margin="mx-auto"
                />
            </div>
            <div
                v-if="message"
                class="
                    px-6
                    text-center text-subtitle-2
                    font-weight-bold
                    pb-6
                    blue--text
                "
            >
                <v-icon color="blue" class="pr-1">
                    mdi-checkbox-marked-circle
                </v-icon>
                <span v-if="message">{{message}}</span>
            </div>
            <v-card-text class="px-8 py-12 pt-0">
                <v-form v-model="valid" @submit="submit">
                    <v-text-field
                        v-model="email"
                        :rules="validationRules"
                        label="Email"
                        required
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        label="Password"
                        :rules="validationRules"
                        required
                        outlined
                    ></v-text-field>

                    <v-btn
                        class="mr-4"
                        block
                        type="submit"
                        color="primary"
                        :disabled="!valid"
                        :loading="loading"
                        depressed
                    >
                        login
                    </v-btn>
                    <div class="text-center pt-6 font-weight-medium">
                        <router-link to="/forgot">
                            Forgot password?
                        </router-link>
                    </div>
                    <div class="text-center pt-4 font-weight-medium">
                        Don't have an account?&nbsp;
                        <router-link to="/register">
                            Register here
                        </router-link>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import AuthInterface from '../../services/AuthInterface.js';

export default {
    data() {
        return {
            valid: false,
            validationRules: [(v) => !!v || 'This field is required'],
            email: '',
            password: '',
            error: null,
            showError: false,
            loading: false,
            showPassword: false,
            message: this.$route.params.message
        };
    },
    methods: {
        submit(event) {
            event.preventDefault();

            this.loading = true;
            const router = this.$router;

            AuthInterface.login({
                email: this.email,
                password: this.password,
            })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    }

                    const { user } = response;

                    if (response.user && response.user.token) {
                        localStorage.setItem('token', user.token);
                        this.$store.currentUser = user;
                    }
                })
                .then(() => {
                    router.push('/projects');
                })
                .catch((e) => {
                    this.error = e;
                    this.showError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.login {
    width: 400px;
    margin: auto;
}

a {
    text-decoration: none;
}
</style>
