export default {
    'general.add': 'Toevoegen',
    'general.edit': 'Bewerking',
    'general.save': 'Opslaan',
    'general.cancel': 'Annuleren',
    'general.delete': 'Verwijderen',
    'general.meters': 'Meter',
    'general.square': 'Vierkante meters',
    'general.cubic': 'Kubieke meters',
    'general.piece': 'Stuk',
    'general.email': 'E-mail',
    'general.password': 'Wachtwoord',
    // toolbar
    'toolbar.english': 'English',
    'toolbar.dutch': 'Nederlands',
    'toolbar.logout': 'Uitloggen',
    // menu
    'menu.projects': 'Projecten',
    'menu.data': 'Leverancier gegevens',
    'menu.profile': 'Mijn profiel',
    'menu.organization': 'Organisatie',
    'menu.users': 'Gebruikers',
    'menu.overview': 'Overzicht',
    'menu.3d': '3D-kijker',
    'menu.offer': 'Offertes',
    // project
    'project.list.title': 'Projecten',
    'project.list.add': 'Project toevoegen',
    'project.list.name': 'Naam',
    'project.list.description': 'Beschrijving',
    'project.list.architect': 'Architect',
    'project.list.constructor': 'Constructeur',
    'project.list.location': 'Locatie',
    'project.list.created': 'Gemaakt bij',
    'project.list.status': 'Toestand',
    'project.add.add': 'Project toevoegen',
    'project.add.edit': 'Project bijwerken',
    'project.add.general': 'Algemene informatie',
    'project.add.name': 'Naam',
    'project.add.description': 'Beschrijving',
    'project.add.file': 'IFC-bestand',
    'project.add.status': 'Toestand',
    'project.add.files': 'IFC-bestanden',
    'project.add.additional': 'Extra informatie',
    'project.add.number': 'Projectnummer',
    'project.add.location': 'Project Locatie',
    'project.add.architect': 'Architect',
    'project.add.construction': 'Bouwbedrijf',
    'project.add.office': 'Installatie bureau',
    'project.add.manager': 'Projectleider',
    'project.add.start': 'Productie start',
    'project.add.time': 'Bouw tijd',
    'project.add.system': 'Constructie systeem',
    'project.add.heat': 'Warmteopwekking',
    'project.status.new': 'Nieuw project',
    'project.status.progress': 'In uitvoering',
    'project.status.done': 'Klaar',
    'project.details.title': 'Algemene informatie',
    'project.details.id': 'ID',
    'project.details.description': 'Beschrijving',
    'project.details.created': 'Aanmaakdatum',
    'project.details.status': 'Toestand',
    'project.details.file': 'IFC-bestand',
    'project.details.additional': 'Extra informatie',
    'project.details.number': 'Projectnummer',
    'project.details.location': 'Project Locatie',
    'project.details.architect': 'Architect',
    'project.details.construction': 'Bouwbedrijf',
    'project.details.office': 'Installatie bureau',
    'project.details.manager': 'Projectleider',
    'project.details.start': 'Productie start',
    'project.details.time': 'Bouw tijd',
    'project.details.system': 'Constructie systeem',
    'project.details.heat': 'Warmteopwekking',
    'project.3d.loading': 'Laden',
    'project.3d.explore': 'Verkennen',
    'project.3d.search': 'Zoeken',
    'project.3d.properties': 'Eigendommen',
    'project.3d.element': 'Elementen informatie',
    'project.3d.name': 'Naam',
    'project.3d.description': 'Beschrijving',
    'project.3d.tag': 'Label',
    'project.3d.quantities': 'Hoeveelheden',
    'project.3d.volume': 'Volume',
    'project.3d.area': 'Gebied',
    'project.3d.length': 'Lengte',
    'project.3d.selection': 'Selectie',
    // prices
    'prices.list.title': 'Leverancier gegevens',
    'prices.list.add': 'Leveranciersgegevens toevoegen',
    'prices.list.name': 'Naam',
    'prices.list.tag': 'Label',
    'prices.list.stabu': 'STABU-code',
    'prices.list.unit': 'Meeteenheid',
    'prices.list.price': 'Prijs / eenheid',
    'prices.add.add': 'Leveranciersgegevens toevoegen',
    'prices.add.edit': 'Update leveranciersgegevens',
    'prices.add.name': 'Naam',
    'prices.add.tag': 'Label',
    'prices.add.unit': 'Meeteenheid',
    'prices.add.price': 'Prijs / eenheid',
    // profile
    'profile.title': 'Persoonlijke gegevens',
    'profile.name.first': 'Voornaam',
    'profile.name.last': 'Achternaam',
    'profile.join': 'Sluit datum aan',
    'profile.email.current': 'Uw huidige e-mailadres is',
    'profile.email.new': 'Nieuwe e-mail',
    'profile.email.confirm': 'E-mail bevestiging',
    'profile.email.check': 'Controleer uw nieuwe e-mail om te bevestigen dat u het bent en voltooi deze actie of',
    'profile.email.undo': 'E-mailwijziging ongedaan maken',
    'profile.password.new': 'Nieuw paswoord',
    'profile.password.confirm': 'Wachtwoordbevestiging',
    'profile.deactivate': 'Deactiveer je account',
    // organization
    'organization.title': 'Organisatie gegevens',
    'organization.name': 'Naam',
    'organization.country': 'Land',
    'organization.permission': 'U heeft geen rechten om deze pagina te zien.',
    // offer
    'offer.list.title': 'Offertes',
    'offer.list.add': 'Voeg offerte toe',
    'offer.list.name': 'Naam',
    'offer.list.versions': 'Versies',
    'offer.list.client': 'Cliënt',
    'offer.list.total': 'Totaal',
    'offer.list.file': 'IFC-bestand',
    'offer.list.created': 'Gemaakt bij',
    'offer.preview.stabu': 'STABU',
    'offer.preview.name': 'Naam',
    'offer.preview.quantity': 'Hoeveelheid',
    'offer.preview.unit': 'Eenheid',
    'offer.preview.rate': 'Uurtarief',
    'offer.preview.hours': 'Uren',
    'offer.preview.material': 'Materiaal',
    'offer.preview.subcontractor': 'Onderaannemer',
    'offer.preview.equipment': 'Apparatuur',
    'offer.preview.total': 'Totaal',
};
