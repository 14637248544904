<template>
    <div>
        <div class="pa-16">
            <div class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6
                        d-flex
                        justify-space-between
                        align-item-center">
                <div class="align-center">
                    <v-icon x-large left>mdi-clipboard-list</v-icon> {{ $t('offer.list.title') }}
                </div>
                <v-btn depressed
                       v-if="$store.project"
                       color="primary float-right"
                       :to="`/project/${$store.project._id}/offers/add`">
                    <v-icon left>mdi-clipboard-plus</v-icon>
                    {{ $t('offer.list.add') }}
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="pt-8">
                <v-card outlined>
                    <v-simple-table v-model="offers" class="text-no-wrap">
                        <thead>
                            <tr>
                                <th>{{ $t('offer.list.name') }}</th>
                                <th>{{ $t('offer.list.versions') }}</th>
                                <th>{{ $t('offer.list.client') }}</th>
                                <th>{{ $t('offer.list.total') }}</th>
                                <th>{{ $t('offer.list.file') }}</th>
                                <th>{{ $t('offer.list.created') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="offers.length">
                                <tr v-for="(offer, index) in offers" :key="index">
                                    <td>
                                        <router-link :to="`/project/${$store.project._id}/offers/${offer._id}`">
                                            {{ offer.versions[0].name }}
                                        </router-link>
                                    </td>
                                    <td>{{ offer.versions.length }}</td>
                                    <td>{{ offer.versions[0].clientName }}</td>
                                    <td><Price :value="offer.versions[0].total" /></td>
                                    <td>{{ offer.file.name }}</td>
                                    <td>{{ offer.created | moment('calendar') }}</td>
                                    <td class="text-right">
                                        <v-menu offset-y bottom left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="dark"
                                                       icon
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click.prevent="deleteOffer($store.project._id, offer._id, offer.name)">
                                                    <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="4" class="text-center font-italic">
                                        No offers found.
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </div>
            <ConfirmationModal ref="confirm"></ConfirmationModal>
        </div>
    </div>
</template>

<script>
import Price from '../../components/Property/Price';
import ConfirmationModal from '../../components/ConfirmationModal';
import OfferInterface from '../../services/OfferInterface.js';

export default {
    data() {
        return { offers: [] };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            if (this.$store.project) {
                OfferInterface.getOffers(this.$store.project._id)
                    .then(offers => this.offers = offers);
            }
        },
        async deleteOffer(projectId, id, name) {
            if (await this.$refs.confirm.open(
                'Delete quotation',
                `Are you sure you want to delete ${name}?`,
                'Delete',
                'mdi-delete'
            )) {
                await OfferInterface.removeOffer(projectId, id);
                this.refresh();
            }
        }
    },
    components: {
        Price,
        ConfirmationModal
    }
};
</script>
