<template>
    <div class="mt-n1">
        <div
            class="d-flex"
            :class="{
                'mb-4': !stabuError,
                'v-otp-input-error': stabuError,
            }"
        >
            <v-icon class="mr-2" :class="{'error-color': stabuError}">mdi-pound-box</v-icon>
            <span
                class="mr-2 mt-6"
                :class="{
                    'grey--text text--darken-1': !stabuError,
                    'error-color': stabuError
                }">STABU*</span>
            <v-otp-input
                length="2"
                v-model="stabuCodeParts[0]"
                @input="onChange"
            ></v-otp-input>
            <span class="mt-8 mx-6" :class="{'error-color': stabuError}">•</span>
            <v-otp-input
                ref="stabuInput2"
                length="2"
                v-model="stabuCodeParts[1]"
                @input="onChange"
            ></v-otp-input>
            <span class="mt-8 mx-6" :class="{'error-color': stabuError}">•</span>
            <v-otp-input
                ref="stabuInput3"
                length="2"
                v-model="stabuCodeParts[2]"
                @input="onChange()"
            ></v-otp-input>
        </div>
        <div v-if="stabuError" class="ml-11 error-text mb-2">Invalid STABU code</div>
    </div>
</template>

<script>
import {STABU_CODE_REGEX} from "@/constants";

export default {
    name: "StabuCodeInput",
    props: ['value'],
    data() {
        return {
            stabuCode: this.value,
            stabuCodeParts: ['', '', ''],
            stabuError: false,
        };
    },
    created() {
        this.stabuCodeParts = this.value.split('.');
    },
    methods: {
        onChange() {
            this.stabuCode = this.stabuCodeParts.join('.');
            this.stabuError = !STABU_CODE_REGEX.test(this.stabuCode);
            this.$emit('input', this.stabuCode)
            this.$emit('onChange');
        },
    }
}
</script>

<style scoped>
.error-text {
    color: #B71C1C;
    font-size: 12px;
}
.v-otp-input-error >>> fieldset {
    border-color: #B71C1C !important;
    border-width: 2px;
}
.error-color {
    color: #B71C1C !important;
}
</style>