<template>
    <div>
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>

        <div class="pa-16">
            <div v-if="!id" class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6">
                <v-icon x-large left>mdi-folder-plus</v-icon>
                {{ $t('project.add.add') }}
            </div>
            <div v-else class="pb-8
                          font-weight-bold
                          text-uppercase
                          text-h6">
                <v-icon x-large left>mdi-folder-edit</v-icon>
                {{ $t('project.add.edit') }}
            </div>

            <v-divider class="mb-6"></v-divider>

            <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">{{ $t('project.add.general') }}</div>
            <v-flex md12 lg6>
                <v-form v-model="valid">
                    <v-text-field
                        outlined
                        v-model="newProject.name"
                        :rules="nameRules"
                        prepend-icon="mdi-folder"
                        :label="$t('project.add.name') + '*'"
                        @input="onChange"
                        required
                    >
                    </v-text-field>

                    <v-textarea
                        outlined
                        v-model="newProject.description"
                        :rules="descriptionRules"
                        prepend-icon="mdi-text-box"
                        :label="$t('project.add.description')"
                        rows="1"
                        auto-grow
                        @input="onChange"
                    >
                    </v-textarea>

                    <v-file-input
                        v-if="!id"
                        outlined
                        v-model="ifcFile"
                        :rules="ifcRules"
                        accept=".ifc,.IFC"
                        prepend-icon="mdi-cube"
                        :label="$t('project.add.file') + '*'"
                        truncate-length="15"
                        show-size
                        @input="onChange"
                        required
                    >
                    </v-file-input>

                    <v-select
                        v-if="id"
                        outlined
                        prepend-icon="mdi-clipboard-check"
                        v-model="newProject.status"
                        :label="$t('project.add.status')"
                        :items="statuses"
                        item-text="text"
                        item-value="value"
                        @input="onChange"
                    ></v-select>

                    <div v-if="id && newProject.files" class="d-flex">
                        <v-select
                            v-model="selectedIfcFile"
                            outlined
                            :label="$t('project.add.files')"
                            :items="newProject.files"
                            item-text="name"
                            prepend-icon="mdi-cube"
                        >
                        </v-select>
                        <v-btn icon small class="mt-4 ml-n16" @click="deleteFile">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <input ref="fileUpload" type="file" @change="addFile" hidden>
                        <v-btn depressed color="primary" class="add-btn ml-11" @click="$refs.fileUpload.click()">
                            <v-icon left>mdi-plus</v-icon>{{ $t('general.add') }}
                        </v-btn>
                    </div>

                    <v-divider class="mt-2 mb-6"></v-divider>

                    <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">
                        {{ $t('project.add.additional') }}
                    </div>

                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.projectNumber"
                                outlined
                                :label="$t('project.add.number')"
                                prepend-icon="mdi-folder-pound"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.projectLocation"
                                outlined
                                :label="$t('project.add.location')"
                                prepend-icon="mdi-map-marker"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.architect"
                                outlined
                                :label="$t('project.add.architect')"
                                prepend-icon="mdi-ruler-square"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.constructionCompany"
                                outlined
                                :label="$t('project.add.construction')"
                                prepend-icon="mdi-account-hard-hat"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.installationOffice"
                                outlined
                                :label="$t('project.add.office')"
                                prepend-icon="mdi-office-building"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.projectManager"
                                outlined
                                :label="$t('project.add.manager')"
                                prepend-icon="mdi-account-tie"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.productionStart"
                                outlined
                                :label="$t('project.add.start')"
                                prepend-icon="mdi-clock-start"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.buildTime"
                                outlined
                                :label="$t('project.add.time')"
                                prepend-icon="mdi-clock-end"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.constructionSystem"
                                outlined
                                :label="$t('project.add.system')"
                                prepend-icon="mdi-wall"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newProject.heatGeneration"
                                outlined
                                :label="$t('project.add.heat')"
                                prepend-icon="mdi-heating-coil"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>

                <div class="d-flex justify-end mt-8">
                    <div class="d-flex justify-end">
                        <v-btn color="secondary"
                               class="mr-4"
                               outlined
                               @click="cancel">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('general.cancel') }}
                        </v-btn>
                        <v-btn color="primary"
                               depressed
                               @click="submit"
                               :disabled="!valid || !changed"
                               :loading="loading">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('general.save') }}
                        </v-btn>
                    </div>
                </div>
            </v-flex>
        </div>
    </div>
</template>

<script>
import ProjectInterface from '../../services/ProjectInterface';

export default {
    data() {
        return {
            valid: false,
            changed: false,
            id: null,
            // todo refactor
            statuses: [
                {text: this.$t('project.status.new'), value: 'new'},
                {text: this.$t('project.status.progress'), value: 'in progress'},
                {text: this.$t('project.status.done'), value: 'done'}
            ],
            newProject: {
                name: '',
                description: '',
                status: 'new',
                projectNumber: '',
                projectLocation: '',
                architect: '',
                constructionCompany: '',
                installationOffice: '',
                projectManager: '',
                productionStart: '',
                buildTime: '',
                constructionSystem: '',
                heatGeneration: ''
            },
            ifcFiles: [],
            selectedIfcFile: null,
            ifcFile: null,
            oldProject: null,
            filesChanged: false,
            allProjects: [],
            error: null,
            showError: false,
            loading: false,
            nameRules: [
                v => !!v || 'Name is required',
                v => v.length >= 5 || 'Minimum 5 characters required',
                v => v.length <= 30 || 'Maximum 30 characters allowed',
                v => !this.allProjects.some(project => project._id !== this.id && project.name === v) || 'Project with this name already exists'
            ],
            descriptionRules: [
                v => v.length <= 200 || 'Maximum 200 characters allowed'
            ],
            ifcRules: [
                v => !!v || 'IFC File is required',
                v => !v || v.size < 100000000 || 'File size should be less than 100 MB'
            ]
        };
    },
    async created() {
        this.id = this.$route.params.id;
        if (this.id) {
            await ProjectInterface.getProjectById(this.id).then((response) => {
                this.newProject = {...response};
                this.selectedIfcFile = this.newProject.files[this.newProject.activeFile].name;
                this.oldProject = response;
            }).catch((error) => {
                this.error = error.response.data.message;
                this.showError = true;
            });
        }
        this.allProjects = await ProjectInterface.getAllProjects();
    },
    methods: {
        submit() {
            this.error = null;
            this.loading = true;

            if (!this.id) {
                let formData = Object.keys(this.newProject).reduce((formData, key) => {
                    formData.append(key, this.newProject[key]);
                    return formData;
                }, new FormData());
                formData.append('ifcFile', this.ifcFile);

                ProjectInterface.createProject(formData)
                    .then(response => {
                        if (response.message) {
                            throw new Error(response.message);
                        } else {
                            this.$router.go(-1);
                        }
                    })
                    .catch(error => {
                        this.error = error;
                        this.showError = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                let formData = Object.keys(this.newProject).reduce((formData, key) => {
                    if (key !== 'files') formData.append(key, this.newProject[key]);
                    return formData;
                }, new FormData());

                formData.append('files', JSON.stringify(this.newProject.files));
                for (let file of this.ifcFiles) {
                    formData.append('ifcFiles', file);
                }

                ProjectInterface.updateProject(this.id, formData)
                    .then(response => {
                        if (response.message) {
                            throw new Error(response.message);
                        } else {
                            this.$router.go(-1);
                        }
                    })
                    .catch(error => {
                        this.error = error;
                        this.showError = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        addFile() {
            const file = this.$refs.fileUpload.files[0];
            if (!this.newProject.files.find(f => f.name === file.name)) {
                this.newProject.files.push({name: file.name});
                this.ifcFiles.push(file);
                this.selectedIfcFile = file.name;
                this.filesChanged = true;
                this.onChange();
            } else {
                this.error = 'File with this name already exists';
                this.showError = true;
            }
        },
        deleteFile() {
            if (this.newProject.files.length > 1) {
                let index = this.newProject.files.findIndex(file => file.name === this.selectedIfcFile);
                this.newProject.files.splice(index, 1);
                this.selectedIfcFile = this.newProject.files[index !== 0 ? index - 1 : 0].name;
                this.filesChanged = true;
                this.onChange();
            } else {
                this.error = 'You cannot delete the last IFC file';
                this.showError = true;
            }
        },
        cancel() {
            this.$router.go(-1);
        },
        onChange() {
            if (this.id && !this.filesChanged) {
                for (let key of Object.keys(this.newProject)) {
                    if (this.oldProject[key] !== this.newProject[key]) {
                        this.changed = true;
                        return;
                    }
                }
                this.changed = false;
            } else {
                this.changed = true;
            }
        },
    }
};
</script>

<style scoped>
.add-btn {
    height: 55px !important;
}
</style>
