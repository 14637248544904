class AuthInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static register(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/register`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static login(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/login`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static logout() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/logout`, {
            method: 'POST',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static confirmEmail(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/verify`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static forgotPass(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/forgot`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static resetPass(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/reset`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static isLoggedIn(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/auth/isLoggedIn`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        })
    }
}

export default AuthInterface;
