<template>
    <div class="d-flex fill-height">
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <v-card class="register" outlined>
            <div class="d-flex py-12">
                <v-img
                    contain
                    alt="Mooduul"
                    src="@/assets/logo.svg"
                    transition="scale-transition"
                    width="410"
                    max-height="140"
                    margin="mx-auto"
                />
            </div>
            <v-card-text class="px-8 py-12 pt-0">
                <v-form v-model="valid" @submit="submit">
                    <v-text-field
                        v-model="organizationName"
                        :disabled="verifiedInvitation"
                        :rules="[
                            ...validationRules.required,
                            ...validationRules.lettersOnly,
                        ]"
                        label="Organization name"
                        required
                        outlined
                    ></v-text-field>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="firstName"
                                :rules="[
                                    ...validationRules.required,
                                    ...validationRules.lettersOnly,
                                ]"
                                label="First name"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="lastName"
                                :rules="[
                                    ...validationRules.required,
                                    ...validationRules.lettersOnly,
                                ]"
                                label="Last name"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="email"
                        :rules="[
                            ...validationRules.required,
                            ...validationRules.email,
                        ]"
                        label="Email"
                        :disabled="verifiedInvitation"
                        required
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :rules="validationRules.required"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        label="Password"
                        required
                        outlined
                    ></v-text-field>

                    <v-btn
                        class="mr-4"
                        block
                        color="primary"
                        @click="
                            verifiedInvitation
                                ? acceptInvitation($event)
                                : submit($event)
                        "
                        :disabled="!valid"
                        :loading="loading"
                        depressed
                    >
                        register
                    </v-btn>
                    <div class="text-center pt-8">
                        Already have an account?
                        <router-link
                            to="/login"
                            style="text-decoration: none; font-weight: 500"
                        >
                            Login here
                        </router-link>
                    </div>
                </v-form></v-card-text
            ></v-card
        >
    </div>
</template>

<script>
import AuthInterface from '../../services/AuthInterface.js';
import UserInterface from '../../services/UserInterface.js';
import { EMAIL_REGEX, ONLY_LETTERS_REGEX } from '../../constants.js';

export default {
    data() {
        return {
            valid: false,
            validationRules: {
                required: [(v) => !!v.trim() || 'This field is required'],
                email: [
                    (v) =>
                        EMAIL_REGEX.test(v.toLowerCase()) ||
                        'Invalid email address',
                ],
                lettersOnly: [
                    (v) => ONLY_LETTERS_REGEX.test(v) || 'Only letters allowed',
                ],
            },
            firstName: '',
            lastName: '',
            organizationName: this.$route.params.organization || '',
            email: this.$route.params.email || '',
            password: '',
            error: null,
            showError: false,
            loading: false,
            showPassword: false,
            verifiedInvitation: this.$route.params.verified,
        };
    },
    methods: {
        submit(event) {
            event.preventDefault();

            this.loading = true;

            AuthInterface.register({
                organizationName: this.organizationName,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password,
            })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    }

                    if (response) {
                        const router = this.$router;
                        router.push({
                            path: '/response',
                            name: 'response',
                            params: {
                                title: 'Check your inbox!',
                                message:
                                    'In order to start using Mooduul, you need to confirm your email address.',
                                icon: 'mdi-email-check',
                                success: true,
                            },
                        });
                    }
                })
                .catch((e) => {
                    this.error = e;
                    this.showError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        acceptInvitation(event) {
            event.preventDefault();

            UserInterface.acceptInvitation({
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password,
                confirmationToken: '',
                status: 'active',
                joinDate: new Date(),
            })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    }

                    const router = this.$router;
                    router.push({
                        path: '/login',
                        name: 'Login',
                        params: { verified: true },
                    });
                })
                .catch((e) => {
                    this.error = e;
                    this.showError = true;
                });
        },
    },
};
</script>

<style scoped>
.register {
    margin: auto;
}
</style>
