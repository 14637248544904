<template>
    <div v-if="params.title" class="d-flex fill-height">
        <v-snackbar v-model="showError" timeout="3000" color="error">
            {{ error }}
        </v-snackbar>
        <v-card class="response-card pa-12">
            <div class="d-flex justify-center">
                <v-icon
                    x-large
                    v-bind:color="success ? 'black' : 'red'"
                    class="pr-1"
                    >
                    {{ icon }}
                </v-icon>
            </div>
            <v-card-title class="text-h5 font-weight-medium justify-center">{{ title }}</v-card-title>
            <v-card-text>
                <div class="text-center font-weight-medium blue-grey--text text--darken-2 py-8">
                    {{ message }}
                </div>
                <v-btn
                    class="mr-4"
                    block
                    type="submit"
                    color="primary"
                >                 
                <router-link to="/" tag="button">Back to home page</router-link>
                </v-btn></v-card-text
        ></v-card>
    </div>
</template>

<script>

export default {
    data() {
        return {
            params: this.$route.params,
            error: null,
            showError: false,
            title: this.$route.params.title,
            message: this.$route.params.message,
            icon: this.$route.params.icon,
            success: this.$route.params.success
        };
    }
};
</script>

<style scoped>
.response-card {
    width: 480px;
    margin: auto;
}
</style>
