<template>
    <div class="d-flex fill-height" v-if="organization && user">
        <v-navigation-drawer permanent class="sidebar">
             <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-uppercase">
                            {{ organization.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            <v-divider></v-divider>
            
            <v-list dense nav class="pb-0">
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    :to="item.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list dense nav class="pt-0">
                <v-list-item
                    v-for="item in organizationItems"
                    :key="item.title"
                    link
                    :to="item.to"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-if="$store.project">
                <v-divider></v-divider>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-uppercase">
                            {{ $store.project.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense nav>
                    <v-list-item
                        v-for="item in projectItems"
                        :key="item.title"
                        link
                        exact
                        :to="item.to"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-main class="pa-0 grey lighten-5 content">
            <Transition name="fade" mode="out-in">
                <router-view></router-view>
            </Transition>
        </v-main>
    </div>
</template>

<script>
import ProjectInterface from "@/services/ProjectInterface";
import UserInterface from '../services/UserInterface.js';
import OrganizationInterface from '../services/OrganizationInterface.js';

export default {
    data() {
        return {
            items: [
                {
                    title: this.$t('menu.projects'),
                    icon: 'mdi-folder-multiple',
                    to: '/projects'
                },
                {
                    title: this.$t('menu.data'),
                    icon: 'mdi-database',
                    to: '/data'
                }
            ],
            organization: null,
            user: null
        };
    },
    computed: {
        projectItems() {
            return [
                {
                    title: this.$t('menu.overview'),
                    icon: 'mdi-folder-open',
                    to: `/project/${this.$store.project._id}`
                },
                {
                    title: this.$t('menu.3d'),
                    icon: 'mdi-rotate-3d',
                    to: `/project/${this.$store.project._id}/3D`
                },
                {
                    title: this.$t('menu.offer'),
                    icon: 'mdi-clipboard-list',
                    to: `/project/${this.$store.project._id}/offers`
                }
            ];
        },
        organizationItems() {
            const items = [
                {
                    title: this.$t('menu.profile'),
                    icon: 'mdi-account',
                    to: '/profile'
                }
            ];
            const adminItems = [
                {
                    title: this.$t('menu.organization'),
                    icon: 'mdi-domain',
                    to: '/organization'
                },
                {
                    title: this.$t('menu.users'),
                    icon: 'mdi-account-group',
                    to: '/users'
                }
            ];
            if (this.user && this.user.admin) {
                items.push(...adminItems);
            }
            return items;
        }
    },
    watch: {
        id() {
            this.getProject();
        },
    },
    methods: {
        getProject() {
            const checkForObjectId = new RegExp("^[0-9a-fA-F]{24}$");
            const projectId = window.location.pathname.split('/')[2];
            if (projectId && checkForObjectId.test(projectId)) {
                ProjectInterface.getProjectById(projectId)
                    .then(project => {
                        if (project._id !== undefined) {
                            this.$store.project = project;
                        }
                    })
                    .catch(() => {
                        localStorage.removeItem('token');
                        window.location.reload();
                    });
            }
        },
        async getCurrentUser() {
            if (this.$store.currentUser) {
                this.user = this.$store.currentUser;
            } else {
                await UserInterface.getCurrentUser().then((response) => {
                    this.user = response;
                    this.$store.currentUser = response;
                });
            }
        },
        async getCurrentOrganization() {
            if (this.$store.currentOrganization) {
                this.organization = this.$store.currentOrganization;
            } else {
                await OrganizationInterface.getCurrentOrganization().then(
                    (response) => {
                        this.organization = response;
                        this.$store.currentOrganization = response;
                    }
                );
            }
        }
    },
    created() {
        this.getProject();
        this.getCurrentUser();
        this.getCurrentOrganization();
    },
};
</script>

<style scoped>
.content {
    max-width: 90%;
}
</style>
