class PriceInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getPrice(fileKey, expressID) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/price`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({fileKey, expressID})
        }).then(response => response.json());
    }
}

export default PriceInterface;
