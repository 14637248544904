<template>
    <div>
        <div v-if="user && user.admin" class="pa-16">
            <div class="pb-8
                        font-weight-bold
                        text-uppercase
                        text-h6
                        d-flex
                        justify-space-between
                        align-item-center">
                <div class="align-center">
                    <v-icon x-large left>mdi-account-group</v-icon> Users
                </div>
                <div>
                    <v-btn
                        depressed
                        type="submit"
                        color="primary"
                        to="/users/form">
                        <v-icon left>mdi-account-plus</v-icon> Invite new users
                    </v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="pt-8">
                <v-card
                    class="pa-6 mb-4"
                    outlined
                    v-for="(user, index) in users"
                    :key="index"
                >
                    <v-row no-gutters align="center">
                        <v-col cols="12" sm="1" class="d-none d-sm-flex">
                            <v-avatar color="grey darken-1">
                                <span
                                    v-if="!user.avatar && user.firstName && user.lastName"
                                    class="
                                        white--text
                                        text-uppercase
                                        font-weight-medium
                                        text-h6
                                    "
                                >
                                    {{ user.firstName[0]
                                    }}{{ user.lastName[0] }}
                                </span>
                                <img v-if="user.avatar" :src="user.avatar">
                                <v-icon
                                    v-if="user.status === 'pending'"
                                    class="pr-1 justify-center"
                                    color="white"
                                >
                                    mdi-email-fast
                                </v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <div
                                class="font-weight-bold text-uppercase text-h8"
                            >
                                {{ user.firstName }} {{ user.lastName }}
                            </div>
                            <div class="grey--text text--darken-1">
                                {{ user.email }}
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div class="row no-gutters">
                                <div class="pr-2 col-4 text-right">
                                    <v-chip
                                        v-if="user.status === 'active'"
                                        color="teal lighten-4"
                                        label
                                        small
                                    >
                                        <span
                                            class="
                                                teal--text
                                                text--darken-4 text-uppercase
                                                font-weight-medium
                                            "
                                        >
                                            Active
                                        </span>
                                    </v-chip>
                                    <v-chip
                                        v-else-if="user.status === 'pending'"
                                        color="amber lighten-4"
                                        label
                                        small
                                    >
                                        <span
                                            class="
                                                amber--text
                                                text--darken-3 text-uppercase
                                                font-weight-medium
                                            "
                                        >
                                            Pending
                                        </span>
                                    </v-chip>
                                    <v-chip
                                        v-else-if="user.status === 'inactive'"
                                        color="red lighten-4"
                                        label
                                        small
                                    >
                                        <span
                                            class="
                                                red--text
                                                text-uppercase
                                                font-weight-medium
                                            "
                                        >
                                            Inactive
                                        </span>
                                    </v-chip>
                                </div>
                                <div class="col-4">
                                    <v-chip
                                        color="blue-grey lighten-4"
                                        label
                                        small
                                    >
                                        <span
                                            class="
                                                blue-grey--text
                                                text--darken-2 text-uppercase
                                                font-weight-medium
                                            "
                                        >
                                            {{
                                                user.admin ? 'Admin' : 'Member'
                                            }}
                                        </span>
                                    </v-chip>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            v-if="user.joinDate"
                            cols="12"
                            sm="2"
                            class="text-subtitle-2"
                        >
                            <div class="">
                                Joined {{ user.joinDate | moment('from') }}
                            </div>
                        </v-col>
                        <v-col cols="12" sm="1" class="text-right ml-auto">
                            <v-menu offset-y bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="dark"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        ><v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-if="
                                            !user.admin &&
                                            user.status !== 'inactive'
                                        "
                                        @click.prevent="makeAdmin(user)"
                                    >
                                        <v-list-item-title>
                                            Make admin
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="user.admin && user.status !== 'inactive'"
                                        @click.prevent="makeMember(user)"
                                        :disabled="isLastAdmin(user)"
                                    >
                                        <v-list-item-title>
                                            Make member
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="user.status === 'pending'"
                                        :to="'/users/form/' + user._id"
                                    >
                                        <v-list-item-title>
                                            Resend invitation
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="user.status === 'pending'"
                                        @click.prevent="cancelInvitation(user)"
                                    >
                                        <v-list-item-title>
                                            Cancel invitation
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="user.status === 'active'"
                                        :disabled="isLastAdmin(user)"
                                        @click.prevent="deactivateUser(user)"
                                    >
                                        <v-list-item-title>
                                            Deactivate
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="user.status === 'inactive'"
                                        @click.prevent="reactivateUser(user)"
                                    >
                                        <v-list-item-title>
                                            Reactivate
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <ConfirmationModal ref="confirmUpdateModal" />
        </div>
        <div class="red--text pa-10" v-if="user && !user.admin">
            You do not have permissions to see this page.
        </div>
        <v-snackbar v-model="showMessage" timeout="3000" :color="color">
            {{ message }}
        </v-snackbar>
    </div>
</template>

<script>
import ConfirmationModal from '../../components/ConfirmationModal';
import UserInterface from '../../services/UserInterface.js';

export default {
    data() {
        return {
            users: [],
            message: null,
            color: null,
            showMessage: false,
            user: null,
        };
    },
    created() {
        UserInterface.getAllUsers()
            .then((response) => {
                if (response.error) {
                    throw new Error(response.error);
                } else {
                    this.users.push(...response);
                }
            })
            .catch((e) => {
                this.message = e;
                this.showMessage = true;
                this.color = 'error';
            });
        this.user = this.$store.currentUser;
    },
    methods: {
        refresh() {
            UserInterface.getAllUsers()
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    } else {
                        this.users = response;
                    }
                })
                .catch((e) => {
                    this.message = e;
                    this.showMessage = true;
                    this.color = 'error';
                });
        },
        async makeAdmin(user) {
            if (
                await this.$refs.confirmUpdateModal.open(
                    'Make admin',
                    'Are you sure you want to make ' +
                        user.firstName +
                        ' ' +
                        user.lastName +
                        ' an admin?',
                    'Make Admin',
                    'mdi-security'
                )
            ) {
                await UserInterface.updateUser(user._id, { admin: true })
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                    })
                    .catch((e) => {
                        this.message = e;
                        this.showMessage = true;
                        this.color = 'error';
                    });
                this.refresh();
            }
        },

        async makeMember(user) {
            if (this.isLastAdmin(user)) {
                this.error = "Can't deactivate the last Admin";
                return this.showError = true;
            }
            if (
                await this.$refs.confirmUpdateModal.open(
                    'Make member',
                    'Are you sure you want to make ' +
                        user.firstName +
                        ' ' +
                        user.lastName +
                        ' a member?',
                    'Make Member',
                    'mdi-account'
                )
            ) {
                await UserInterface.updateUser(user._id, { admin: false });
                this.refresh();
            }
        },

        async deactivateUser(user) {
            if (this.isLastAdmin(user)) {
                this.error = "Can't deactivate the last Admin";
                return this.showError = true;
            }
            if (
                await this.$refs.confirmUpdateModal.open(
                    'Deactivate',
                    'Are you sure you want to deactivate this user: ' +
                        user.firstName +
                        ' ' +
                        user.lastName +
                        ' ?',
                    'Deactivate',
                    'mdi-alert'
                )
            ) {
                await UserInterface.updateUser(user._id, { status: 'inactive' })
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                    })
                    .catch((e) => {
                        this.message = e;
                        this.showMessage = true;
                        this.color = 'error';
                    });
                this.refresh();
            }
        },

        async reactivateUser(user) {
            if (
                await this.$refs.confirmUpdateModal.open(
                    'Reactivate',
                    'Are you sure you want to reactivate this user: ' +
                        user.firstName +
                        ' ' +
                        user.lastName +
                        ' ?',
                    'Reactivate',
                    'mdi-alert'
                )
            ) {
                await UserInterface.updateUser(user._id, { status: 'active' })
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                    })
                    .catch((e) => {
                        this.message = e;
                        this.showMessage = true;
                        this.color = 'error';
                    });
                this.refresh();
            }
        },

        async cancelInvitation(user) {
            if (
                await this.$refs.confirmUpdateModal.open(
                    'Cancel invitation',
                    `Are you sure you want to cancel the invitation for ${user.email}?`,
                    'Cancel invitation',
                    'mdi-cancel'
                )
            ) {
                await UserInterface.deleteUser(user._id)
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                    })
                    .catch((e) => {
                        this.message = e;
                        this.showMessage = true;
                        this.color = 'error';
                    });
                this.refresh();
            }
        },

        isLastAdmin(user) {
            return user.admin && this.users.filter(u => u.status === 'active' && u.admin).length === 1;
        },
    },
    components: {
        ConfirmationModal,
    },
};
</script>

<style scoped>
.v-chip {
    width: 120px;
    justify-content: center;
}
</style>
