class PropertiesInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getQuantities(fileKey, expressID) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/properties/quantities?key=${fileKey}&id=${expressID}`, {
            method: 'GET',
            headers: this.getAuthHeader()
        }).then(response => response.json());
    }

    static getProperties(fileKey, expressID) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/properties`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({fileKey, expressID})
        }).then(response => response.json());
    }
}

export default PropertiesInterface;
