var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-n1"},[_c('div',{staticClass:"d-flex",class:{
            'mb-4': !_vm.stabuError,
            'v-otp-input-error': _vm.stabuError,
        }},[_c('v-icon',{staticClass:"mr-2",class:{'error-color': _vm.stabuError}},[_vm._v("mdi-pound-box")]),_c('span',{staticClass:"mr-2 mt-6",class:{
                'grey--text text--darken-1': !_vm.stabuError,
                'error-color': _vm.stabuError
            }},[_vm._v("STABU*")]),_c('v-otp-input',{attrs:{"length":"2"},on:{"input":_vm.onChange},model:{value:(_vm.stabuCodeParts[0]),callback:function ($$v) {_vm.$set(_vm.stabuCodeParts, 0, $$v)},expression:"stabuCodeParts[0]"}}),_c('span',{staticClass:"mt-8 mx-6",class:{'error-color': _vm.stabuError}},[_vm._v("•")]),_c('v-otp-input',{ref:"stabuInput2",attrs:{"length":"2"},on:{"input":_vm.onChange},model:{value:(_vm.stabuCodeParts[1]),callback:function ($$v) {_vm.$set(_vm.stabuCodeParts, 1, $$v)},expression:"stabuCodeParts[1]"}}),_c('span',{staticClass:"mt-8 mx-6",class:{'error-color': _vm.stabuError}},[_vm._v("•")]),_c('v-otp-input',{ref:"stabuInput3",attrs:{"length":"2"},on:{"input":function($event){return _vm.onChange()}},model:{value:(_vm.stabuCodeParts[2]),callback:function ($$v) {_vm.$set(_vm.stabuCodeParts, 2, $$v)},expression:"stabuCodeParts[2]"}})],1),(_vm.stabuError)?_c('div',{staticClass:"ml-11 error-text mb-2"},[_vm._v("Invalid STABU code")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }