class OrganizationInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getCurrentOrganization() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/organization/current`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static updateOrganization(id, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/organization/${id}`, {
            method: 'PUT',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }
}

export default OrganizationInterface;
