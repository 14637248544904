<template>
    <v-dialog v-model="dialog"
              persistent
              max-width="500">
        <v-card>
            <v-card-text class="pt-5 text-h6">
                {{ message }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="secondary"
                       outlined
                       @click="cancel">
                  <v-icon left>mdi-close</v-icon> Cancel
                </v-btn>
                <v-btn color="primary"
                       depressed
                       @click="confirm">
                    <v-icon left>{{ icon }}</v-icon> {{ btnLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmationModal',
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        title: '',
        message: '',
        btnLabel: '',
        icon: ''
    }),
    methods: {
        open(title, message, btnLabel, icon = '') {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.btnLabel = btnLabel;
            this.icon = icon;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        confirm() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>
