/**
 * @class UserInterface
 *
 * @note this class does not extend from the Interface class since the type of
 *       request is different (not BIMserver, so doesn't need the extra body data)
 */
class UserInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getAllUsers() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static getUser(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/${id}`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }
    
    static getCurrentUser() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/current`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static updateUser(id, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/${id}`, {
            method: 'PUT',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static inviteUsers(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/invite`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static acceptInvitation(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/acceptInvitation`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static resendInvitation(id, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/${id}/resendInvitation`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static deleteUser(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/user/${id}`, {
            method: 'DELETE',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }
}

export default UserInterface;
