<template>
    <v-app>
        <v-app-bar app v-if="token" color="black" class="elevation-0">
            <a href="/" class="d-flex align-center" >
                <v-img
                    contain
                    alt="Mooduul"
                    src="@/assets/logo.svg"
                    transition="scale-transition"
                    width="200"
                    max-height="65"
                    class="mt-2"
                />
            </a>

            <v-spacer></v-spacer>

            <v-select class="language-select mt-6 text-button"
                      :items="languages"
                      v-model="$i18n.locale"
                      @change="languageChanged()"
                      item-text="label"
                      item-value="val"
                      color="white"
                      background-color="black"
                      dense
                      solo>
            </v-select>

            <v-btn @click="logout" target="_blank" text color="white">
                {{ $t('toolbar.logout') }} <v-icon right>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main class="grey lighten-5">
            <router-view :key="$route.path"></router-view>
        </v-main>
    </v-app>
</template>

<script>
import AuthInterface from './services/AuthInterface.js';

export default {
    name: 'App',
    data() {
        return {
            get token() {
                return localStorage.getItem('token') || '';
            },
            languages: [
                {label: this.$t('toolbar.english'), val: 'en'},
                {label: this.$t('toolbar.dutch'), val: 'nl'}
            ]
        };
    },
    methods: {
        logout() {
            const router = this.$router;

            AuthInterface.logout()
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    if (response) {
                        localStorage.clear();
                        this.$store.currentUser = null;
                        this.$store.currentOrganization = null;
                        router.push('/login');
                    }
                })
                .catch(() => {});
        },
        languageChanged() {
            localStorage.setItem('mooduul-language', this.$i18n.locale);
        }
    },
};
</script>
<style lang="scss">
.v-text-field--outlined > .v-input__control > .v-input__slot {
    background: #fff !important;
}

.language-select {
    max-width: 170px;

    .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
        color: white !important;
    }
    .v-icon {
        color: white !important;
    }
}
</style>
