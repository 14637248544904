<template>
    <div class="fill-height">
        <v-snackbar v-if="error" timeout="3000" color="error">{{ error }}</v-snackbar>

        <div v-if="isLoading" class="fill-height d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <!--form-->
        <div v-show="activeTab === 0 && !isLoading" class="pa-16">
            <v-flex md12 lg6>
                <customer-details-form v-if="offerTable" v-model="offerTable"></customer-details-form>

                <div class="d-flex justify-end mt-6">
                    <v-btn color="secondary"
                           outlined
                           @click.native="$router.push(`/project/${$store.project._id}/offers`)"
                           class="mr-4">
                        <v-icon left>mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="primary"
                           depressed
                           @click="activeTab = 1"
                           :disabled="!isFormValid">
                        <v-icon left>mdi-arrow-right</v-icon>
                        Continue
                    </v-btn>
                </div>
            </v-flex>
        </div>

        <!--table-->
        <div v-show="activeTab === 1 && !isLoading">
            <preview-offer v-model="offerTable" class="preview-offer" :editable="true"></preview-offer>

            <div class="bottom-navigation d-flex justify-end pa-4">
                <v-btn color="secondary"
                       outlined
                       @click.native="$router.push(`/project/${$store.project._id}/offers`)"
                       class="mr-4">
                    <v-icon left>mdi-close</v-icon>
                    Cancel
                </v-btn>
                <v-btn color="secondary"
                       depressed
                       @click="activeTab = 0"
                       class="mr-4">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
                <v-btn color="primary"
                       depressed
                       @click="save()"
                       :loading="isLoading"
                       :disabled="!isFormValid || !isTableValid">
                    <v-icon left>mdi-content-save</v-icon>
                    Save
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import OfferInterface from '../../services/OfferInterface';
import CustomerDetailsForm from './CustomerDetails.vue';
import PreviewOffer from './PreviewOffer.vue';

export default {
    components: {
        CustomerDetailsForm,
        PreviewOffer
    },
    data() {
        return {
            activeTab: 0,
            projectId: this.$store.project._id,
            offerId: this.$route.params.offerId,
            version: this.$route.params.version,
            offer: null,
            offerTable: null,
            isLoading: false,
            isFormValid: false,
            isTableValid: true, // todo
            error: null
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        formChanged(valid) {
            this.isFormValid = valid;
        },
        tableChanged(/*valid*/) {
            // this.isTableValid = valid;
        },
        async loadData() {
            this.isLoading = true;

            if (this.offerId) {
                // load offer
                this.offer = await OfferInterface.getOffer(this.projectId, this.offerId);

                if (this.version) {
                    this.offerTable = this.offer.versions[this.version];
                } else {
                    this.offerTable = {...this.offer.versions[this.offer.versions.length - 1]};
                }
            } else {
                // new offer
                const preview = await OfferInterface.preview(this.projectId, {offer: {}});
                this.offerTable = {
                    name: '',
                    description: '',
                    clientName: '',
                    clientEmail: '',
                    clientPhone: '',
                    clientAddress: '',
                    clientCity: '',
                    clientPostcode: '',
                    rows: preview,
                    total: 0
                };
            }

            this.isLoading = false;
        },
        save() {
            this.isLoading = true;
            this.offerTable.total = 0;
            this.offerTable.rows.forEach(chapter => this.offerTable.total += chapter.total || 0);

            if (this.offerId) {
                if (!this.version)
                    this.offer.versions.push(this.offerTable);

                OfferInterface.updateOffer(this.projectId, this.offerId, {versions: this.offer.versions})
                    .then(() => this.$router.push({path: `/project/${this.projectId}/offers/${this.offerId}`}))
                    .catch(error => this.error = error)
                    .finally(() => this.isLoading = false);
            } else {
                OfferInterface.createOffer(this.projectId, this.offerTable)
                    .then(offer => this.$router.push({path: `/project/${this.projectId}/offers/${offer._id}`}))
                    .catch(error => this.error = error)
                    .finally(() => this.isLoading = false);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.preview-offer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 70px;
    left: 0;
    overflow: scroll;
}

.bottom-navigation {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 70px;
    border-top: 1px solid #d5d5d5;
}
</style>
