var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex fill-height"},[_c('v-snackbar',{attrs:{"timeout":"3000","color":"error"},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('v-card',{staticClass:"register",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex py-12"},[_c('v-img',{attrs:{"contain":"","alt":"Mooduul","src":require("@/assets/logo.svg"),"transition":"scale-transition","width":"410","max-height":"140","margin":"mx-auto"}})],1),_c('v-card-text',{staticClass:"px-8 py-12 pt-0"},[_c('v-form',{on:{"submit":_vm.submit},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"disabled":_vm.verifiedInvitation,"rules":[
                        ..._vm.validationRules.required,
                        ..._vm.validationRules.lettersOnly,
                    ],"label":"Organization name","required":"","outlined":""},model:{value:(_vm.organizationName),callback:function ($$v) {_vm.organizationName=$$v},expression:"organizationName"}}),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":[
                                ..._vm.validationRules.required,
                                ..._vm.validationRules.lettersOnly,
                            ],"label":"First name","required":"","outlined":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rules":[
                                ..._vm.validationRules.required,
                                ..._vm.validationRules.lettersOnly,
                            ],"label":"Last name","required":"","outlined":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c('v-text-field',{attrs:{"rules":[
                        ..._vm.validationRules.required,
                        ..._vm.validationRules.email,
                    ],"label":"Email","disabled":_vm.verifiedInvitation,"required":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"rules":_vm.validationRules.required,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Password","required":"","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"block":"","color":"primary","disabled":!_vm.valid,"loading":_vm.loading,"depressed":""},on:{"click":function($event){_vm.verifiedInvitation
                            ? _vm.acceptInvitation($event)
                            : _vm.submit($event)}}},[_vm._v(" register ")]),_c('div',{staticClass:"text-center pt-8"},[_vm._v(" Already have an account? "),_c('router-link',{staticStyle:{"text-decoration":"none","font-weight":"500"},attrs:{"to":"/login"}},[_vm._v(" Login here ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }