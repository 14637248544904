export default {
    'general.add': 'Add',
    'general.edit': 'Edit',
    'general.save': 'Save',
    'general.cancel': 'Cancel',
    'general.delete': 'Delete',
    'general.meters': 'Meters',
    'general.square': 'Square Meters',
    'general.cubic': 'Cubic Meters',
    'general.piece': 'Piece',
    'general.email': 'Email',
    'general.password': 'Password',
    // toolbar
    'toolbar.english': 'English',
    'toolbar.dutch': 'Nederlands',
    'toolbar.logout': 'Log out',
    // menu
    'menu.projects': 'Projects',
    'menu.data': 'Supplier data',
    'menu.profile': 'My profile',
    'menu.organization': 'Organization',
    'menu.users': 'Users',
    'menu.overview': 'Overview',
    'menu.3d': '3D Viewer',
    'menu.offer': 'Quotations',
    // project
    'project.list.title': 'Projects',
    'project.list.add': 'Add project',
    'project.list.name': 'Name',
    'project.list.description': 'Description',
    'project.list.architect': 'Architect',
    'project.list.constructor': 'Constructor',
    'project.list.location': 'Location',
    'project.list.created': 'Created at',
    'project.list.status': 'Status',
    'project.add.add': 'Add project',
    'project.add.edit': 'Update project',
    'project.add.general': 'General information',
    'project.add.name': 'Name',
    'project.add.description': 'Description',
    'project.add.file': 'IFC file',
    'project.add.status': 'Status',
    'project.add.files': 'IFC files',
    'project.add.additional': 'Additional information',
    'project.add.number': 'Project number',
    'project.add.location': 'Project location',
    'project.add.architect': 'Architect',
    'project.add.construction': 'Construction company',
    'project.add.office': 'Installation office',
    'project.add.manager': 'Project manager',
    'project.add.start': 'Production start',
    'project.add.time': 'Build time',
    'project.add.system': 'Construction system',
    'project.add.heat': 'Heat generation',
    'project.status.new': 'New project',
    'project.status.progress': 'In progress',
    'project.status.done': 'Done',
    'project.details.title': 'General information',
    'project.details.id': 'Identifier',
    'project.details.description': 'Description',
    'project.details.created': 'Creation date',
    'project.details.status': 'Status',
    'project.details.file': 'IFC file',
    'project.details.additional': 'Additional information',
    'project.details.number': 'Project number',
    'project.details.location': 'Project location',
    'project.details.architect': 'Architect',
    'project.details.construction': 'Construction company',
    'project.details.office': 'Installation office',
    'project.details.manager': 'Project manager',
    'project.details.start': 'Production start',
    'project.details.time': 'Build time',
    'project.details.system': 'Construction system',
    'project.details.heat': 'Heat generation',
    'project.3d.loading': 'Loading',
    'project.3d.explore': 'Explore',
    'project.3d.search': 'Search',
    'project.3d.properties': 'Properties',
    'project.3d.element': 'Element information',
    'project.3d.name': 'Name',
    'project.3d.description': 'Description',
    'project.3d.tag': 'Tag',
    'project.3d.quantities': 'Quantities',
    'project.3d.volume': 'Volume',
    'project.3d.area': 'Area',
    'project.3d.length': 'Length',
    'project.3d.selection': 'Selection',
    // prices
    'prices.list.title': 'Supplier data',
    'prices.list.add': 'Add supplier data',
    'prices.list.name': 'Name',
    'prices.list.tag': 'Tag',
    'prices.list.stabu': 'STABU code',
    'prices.list.unit': 'Measuring unit',
    'prices.list.price': 'Price / unit',
    'prices.add.add': 'Add supplier data',
    'prices.add.edit': 'Update supplier data',
    'prices.add.name': 'Name',
    'prices.add.tag': 'Tag',
    'prices.add.unit': 'Measuring unit',
    'prices.add.price': 'Price / unit',
    // profile
    'profile.title': 'Personal details',
    'profile.name.first': 'First name',
    'profile.name.last': 'Last name',
    'profile.join': 'Join date',
    'profile.email.current': 'Your current email address is',
    'profile.email.new': 'New email',
    'profile.email.confirm': 'Email confirmation',
    'profile.email.check': 'Check your new email to confirm it\'s you and complete this action or',
    'profile.email.undo': 'Undo email change',
    'profile.password.new': 'New password',
    'profile.password.confirm': 'Password confirmation',
    'profile.deactivate': 'Deactivate your account',
    // organization
    'organization.title': 'Organization details',
    'organization.name': 'Name',
    'organization.country': 'Country',
    'organization.permission': 'You do not have permissions to see this page.',
    // offer
    'offer.list.title': 'Quotations',
    'offer.list.add': 'Add quotation',
    'offer.list.name': 'Add quotation',
    'offer.list.versions': 'Versions',
    'offer.list.client': 'Client',
    'offer.list.total': 'Total',
    'offer.list.file': 'IFC file',
    'offer.list.created': 'Created at',
    'offer.preview.stabu': 'STABU',
    'offer.preview.name': 'Name',
    'offer.preview.quantity': 'Quantity',
    'offer.preview.unit': 'Unit',
    'offer.preview.rate': 'Rate',
    'offer.preview.hours': 'Hours',
    'offer.preview.material': 'Material',
    'offer.preview.subcontractor': 'Subcontractor',
    'offer.preview.equipment': 'Equipment',
    'offer.preview.total': 'Total',
};
