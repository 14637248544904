<template>
    <v-form @input="onInput($event)">
        <div class="pb-8
            font-weight-bold
            text-uppercase
            text-h6">
            <v-icon x-large left>mdi-clipboard-plus</v-icon>
            Add quotation
        </div>

        <v-divider class="mb-6"></v-divider>
        <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">Quotation</div>

        <v-text-field v-model="value.name"
                      outlined
                      label="Quotation name*"
                      prepend-icon="mdi-clipboard"
                      :rules="nameRules"
                      required>
        </v-text-field>

        <v-textarea
            v-model="value.description"
            outlined
            label="Description"
            prepend-icon="mdi-text-box"
            :rules="descriptionRules"
            rows="1"
            auto-grow>
        </v-textarea>

        <v-divider class="mt-6 mb-6"></v-divider>
        <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">Client info</div>

        <v-text-field v-model="value.clientName"
                      outlined
                      label="Client name*"
                      prepend-icon="mdi-account"
                      :rules="clientNameRules"
                      required>
        </v-text-field>

        <v-text-field v-model="value.clientEmail"
                      outlined
                      label="Email*"
                      prepend-icon="mdi-email"
                      :rules="clientEmailRules"
                      required>
        </v-text-field>

        <v-text-field v-model="value.clientPhone"
                      outlined
                      label="Phone number*"
                      prepend-icon="mdi-phone"
                      :rules="clientPhoneRules"
                      required>
        </v-text-field>

        <v-text-field v-model="value.clientAddress"
                      outlined
                      label="Address*"
                      prepend-icon="mdi-map-marker"
                      :rules="clientAddressRules"
                      required>
        </v-text-field>

        <v-text-field v-model="value.clientCity"
                      outlined
                      label="City*"
                      prepend-icon="mdi-city"
                      :rules="clientCityRules"
                      required>
        </v-text-field>

        <v-text-field v-model="value.clientPostcode"
                      outlined
                      label="Postcode*"
                      prepend-icon="mdi-map-marker-radius"
                      :rules="clientPostcodeRules"
                      required>
        </v-text-field>
    </v-form>
</template>

<script>
import {EMAIL_REGEX, PHONE_NUMBER_REGEX, ONLY_LETTERS_REGEX} from '@/constants';

export default {
    props: ['value'],
    data() {
        return {
            nameRules: [
                v => !!v || 'Quotation name is required',
                v => v.length >= 5 || 'Minimum 5 characters required',
                v => v.length <= 30 || 'Maximum 30 characters allowed'
            ],
            descriptionRules: [
                v => v.length <= 200 || 'Maximum 200 characters allowed'
            ],
            clientNameRules: [
                v => ONLY_LETTERS_REGEX.test(v) && v.length >= 3 && v.length <= 50 || 'Name not valid'
            ],
            clientEmailRules: [
                v => EMAIL_REGEX.test(v.toLowerCase()) || 'Email address not valid'
            ],
            clientPhoneRules: [
                v => PHONE_NUMBER_REGEX.test(v) || 'Phone number not valid'
            ],
            clientAddressRules: [
                v => v.length >= 5 && v.length <= 50 || 'Address not valid'
            ],
            clientCityRules: [
                v => ONLY_LETTERS_REGEX.test(v) && v.length >= 3 && v.length <= 50 || 'City name not valid'
            ],
            clientPostcodeRules: [
                v => v.length >= 3 && v.length <= 10 || 'Postcode not valid'
            ]
        };
    },
    methods: {
        onInput(e) {
            this.$parent.formChanged(e);
        }
    }
};
</script>
