<template>
    <div class="pa-16 fill-height">
        <v-snackbar v-model="showMessage" timeout="3000" :color="color">
            {{ message }}
        </v-snackbar>
        <div class="pb-8 font-weight-bold text-uppercase text-h6 d-flex align-center">
            <v-avatar color="blue-grey lighten-3" size="64" class="cursor-pointer" @click="changeAvatar">
                <span v-if="!newUser.avatar" class="white--text text-uppercase font-weight-bold text-h6">
                    {{ user.firstName[0] }}{{ user.lastName[0] }}
                </span>
                <img v-if="newUser.avatar" :src="newUser.avatar">
                <input ref="avatarInput" type="file" accept="image/*" hidden @change="avatarChanged">
            </v-avatar>
            <div class="pl-4">
                <div> {{ user.firstName }} {{ user.lastName }} </div>
                <v-chip color="blue-grey lighten-1 px-4" label small>
                    <span class="white--text text-uppercase font-weight-medium">
                        {{ user.admin ? 'Admin' : 'Member' }}
                    </span>
                </v-chip>
            </div>
        </div>
        
        <v-divider></v-divider>
        
        <div class="pt-8">
            <v-flex md12 lg6>
                <v-form v-model="valid" @submit="submit" ref="editUserForm">
                    <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">
                        {{ $t('profile.title') }}
                    </div>
                    <v-row>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newUser.firstName"
                                :rules="nameRules"
                                :label="$t('profile.name.first')"
                                outlined
                                @input="onChange"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model="newUser.lastName"
                                :rules="nameRules"
                                :label="$t('profile.name.last')"
                                outlined
                                @input="onChange"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="joinDate"
                                :label="$t('profile.join')"
                                readonly
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col>
                        </v-col>
                    </v-row>
                    <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">{{ $t('general.email') }}</div>
                    <div class="text-body-2 pb-4">{{ $t('profile.email.current') }} <b>{{user.email}}</b></div>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="newUser.newEmail"
                                :rules="emailRules"
                                :label="$t('profile.email.new')"
                                outlined
                                readonly
                                onfocus="this.removeAttribute('readonly');"
                                :disabled="loading"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="newUser.newEmailConfirmation"
                                :rules="emailRules.concat(emailConfrmationRules)"
                                :label="$t('profile.email.confirm')"
                                outlined
                                readonly
                                onfocus="this.removeAttribute('readonly');"
                                :disabled="loading"
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="user.unconfirmedEmail" class="mb-4">
                        <v-alert
                            dense
                            text
                            type="success"
                            border="left"
                            colored-border
                            >
                            <span>{{ $t('profile.email.check') }}</span>
                            <v-btn
                                small
                                text
                                color="error"
                                class="pl-2"
                                @click="undoEmailChange"
                                >
                                {{ $t('profile.email.undo') }}
                            </v-btn>
                            </v-alert>
                        </v-row>
                    <div class="grey--text text--darken-2 text-overline text-uppercase pb-4">{{ $t('general.password') }}</div>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="newUser.newPassword"
                                :rules="passwordRules"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                :label="$t('profile.password.new')"
                                readonly
                                onfocus="this.removeAttribute('readonly');"
                                outlined
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="newUser.passwordConfirmation"
                                :rules="passwordRules.concat(passwordConfrmationRules)"
                                :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPasswordConfirmation ? 'text' : 'password'"
                                @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                                :label="$t('profile.password.confirm')"
                                readonly
                                onfocus="this.removeAttribute('readonly');"
                                outlined
                                @input="onChange"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn
                        class="pl-2"
                        color="error"
                        plain
                        small
                        @click.prevent="deactivateAccount"
                    >
                        <v-icon left>mdi-alert</v-icon> {{ $t('profile.deactivate') }}
                    </v-btn>
                    <div class="d-flex justify-end mt-4">
                        <v-btn
                            depressed
                            type="submit"
                            color="primary"
                            :disabled="!valid || !changed"
                            :loading="loading">
                            <v-icon left>mdi-content-save</v-icon> {{ $t('general.save') }}
                        </v-btn>
                    </div>
                </v-form>
            </v-flex>
            <ConfirmationModal ref="confirmDeactivateAccount"></ConfirmationModal>
        </div>
    </div>
</template>

<script>
import UserInterface from '../../services/UserInterface.js';
import ConfirmationModal from '../../components/ConfirmationModal';
import { EMAIL_REGEX, ONLY_LETTERS_REGEX } from '../../constants.js';
import moment from "moment";

export default {
    data() {
        return {
            user: {},
            newUser: {
                avatar: null,
                newEmail: '',
                newEmailConfirmation: '',
                newPassword: '',
                passwordConfirmation: ''
            },
            joinDate: '',
            initialData: {},
            changed: false,
            showError: false,
            message: null,
            color: null,
            showMessage: false,
            valid: false,
            loading: false,
            showPassword: false,
            showPasswordConfirmation: false,
            currentPassword: '',
            newPassword: '',
            nameRules: [
                v => !!v || 'Name is required',
                v => ONLY_LETTERS_REGEX.test(v) || 'Only letters allowed',
            ],
            emailRules: [
                v => v.trim().length > 0 ? EMAIL_REGEX.test(v.toLowerCase()) || 'Email must be valid' : true,
            ],
            emailConfrmationRules: [
                v => this.newUser.newEmail.trim().length ? (!!v && this.newUser.newEmail.length > 0) || 'Email confirmation is required' : true,
                v => v === this.newUser.newEmail || 'The email confirmation does not match.',
            ],
            passwordRules: [
                v => v.trim().length ? v.length >= 5 || 'Minimum 5 characters required': true,
                v => v.trim().length ? v.length <= 30 || 'Maximum 30 characters allowed' : true
            ],
            passwordConfrmationRules: [
                v => this.newUser.newPassword.length ? (!!v && this.newUser.newPassword.length > 0) || 'Password confirmation is required' : true,
                v => v === this.newUser.newPassword || 'The password confirmation does not match.',
            ],
        };
    },
    created() {
        this.user = this.$store.currentUser;
        this.newUser = { ...this.newUser, ...this.user };
        this.joinDate = moment(this.user.joinDate).format('DD/MM/YYYY')
        this.initialData = { ...this.newUser };
    },
    methods: {
      moment() {
        return moment
      },
        changeAvatar() {
            if (this.user.status !== 'active') return;
            this.$refs.avatarInput.click();
        },
        avatarChanged(e) {
            const file = e.target.files[0];
            if (!file) return;
            if (file.size > 2097152) {
                this.message = 'Maximum 2MB file size is allowed!';
                this.showMessage = true;
                this.color = 'error';
                return;
            }
            const reader = new FileReader();
            reader.onload = e => this.newUser.avatar = e.target.result;
            reader.readAsDataURL(file);
            this.changed = true;
        },

        validateForm() {
            const form = this.$refs.editUserForm;
            form.validate();
            return form.inputs.every(e => e.valid);
        },

        onChange() {
            this.changed = JSON.stringify(this.newUser) !== JSON.stringify(this.initialData);
        },

        submit(event) {
            event.preventDefault();
    
            const validForm = this.validateForm();
            
            if (!validForm) return false;

            this.loading = true;

            UserInterface.updateUser(this.user._id, { ...this.newUser })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    } else {
                        this.message = 'Your changes were saved.';
                        this.showMessage = true;
                        this.color = 'success';
                        this.user = response;
                        this.newUser = { ...this.newUser, ...this.user };
                        this.$store.currentUser = response;
                        this.changed = false;
                    }
                })
                .catch((e) => {
                    this.message = e;
                    this.showMessage = true;
                    this.color = 'error';
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        undoEmailChange() {
            UserInterface.updateUser(this.user._id, { unconfirmedEmail: '', confirmationToken: '' })
                .then((response) => {
                    if (response.error) {
                        throw new Error(response.error);
                    } else {
                        this.message = 'Your changes were saved.';
                        this.showMessage = true;
                        this.color = 'success';
                        this.user = response;
                        this.newUser.newEmail = '';
                        this.newUser.newEmailConfirmation = '';
                        this.newUser = {...this.newUser, ...this.user};
                        this.$store.currentUser = response;
                    }
                })
                .catch((e) => {
                    this.message = e;
                    this.showMessage = true;
                    this.color = 'error';
                });
        },
        async deactivateAccount() {
            if (
                await this.$refs.confirmDeactivateAccount.open(
                    'Deactivate Account',
                    "Are you sure you want to deactivate your account? You won't be able to use Mooduul anymore.",
                    'Deactivate',
                    'mdi-alert'
                )
            ) {
                await UserInterface.updateUser(this.user._id, { status: 'inactive', token: '' })
                    .then((response) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                        
                        localStorage.clear();
                        this.$store.currentUser = null;
                        this.$store.currentOrganization = null;
                        this.$router.push({path: '/login', name: 'Login', params: {message: 'Your account has been deactivated!'}});
                    })
                    .catch((e) => {
                        this.message = e;
                        this.showMessage = true;
                        this.color = 'error';
                    });
            }
        },
    },
    components: {
        ConfirmationModal,
    },
};
</script>

<style>
    .cursor-pointer {
        cursor: pointer;
    }
</style>
