<template>
    <v-container class="model-viewer">

        <!--preloader-->
        <v-card class="preloader" v-if="loadingProgress < 100" outlined>
            <v-card-text class="primary white--text font-weight-bold text-center">
                {{ $t('project.3d.loading') }}: {{ loadingProgress }} %
            </v-card-text>
        </v-card>

        <!--tree-->
        <v-card class="tree" v-show="!treeClosed" outlined>
            <v-card-title>
                <v-icon class="mr-2">mdi-file-tree-outline</v-icon> {{ $t('project.3d.explore') }}
                <v-btn icon x-small class="close-btn" @click="treeClosed = true;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-text-field class="ma-4 mt-0"
                          v-model="search"
                          :label="$t('project.3d.search')"
                          flat
                          dense
                          outlined
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline">
            </v-text-field>

            <v-card-text class="pa-0">
                <v-treeview class="mb-4"
                            activatable
                            hoverable
                            :items="items"
                            item-key="expressID"
                            item-text="Name.value"
                            :search="search">
                    <template v-slot:label="{ item, open }">
                        <span @click="treeClicked(item)">
                            <v-icon v-if="item.children.length > 0">
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                            <v-icon v-else>mdi-file</v-icon>
                            {{ item.Name.value }}
                        </span>
                    </template>
                </v-treeview>
            </v-card-text>
        </v-card>

        <v-btn class="open-tree"
               v-if="treeClosed"
               @click="treeClosed = false;"
               fab
               small
               depressed
               color="primary">
            <v-icon>mdi-file-tree-outline</v-icon>
        </v-btn>

        <!--properties-->
        <v-card class="props" v-if="this.selectedExpressID" v-show="!propsClosed" outlined>
            <v-card-title>
                <v-icon class="mr-2">mdi-view-list-outline</v-icon> {{ $t('project.3d.properties') }}
                <v-btn icon x-small class="close-btn" @click="propsClosed = true;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0">
                <Properties v-bind="{ properties, quantities, price }"/>
            </v-card-text>
        </v-card>

        <v-btn class="open-props"
               v-if="propsClosed"
               @click="propsClosed = false;"
               fab
               small
               depressed
               color="primary">
            <v-icon>mdi-view-list-outline</v-icon>
        </v-btn>

        <!--controls-->
        <v-card class="controls px-4" outlined>
            <span>{{ $t('project.3d.selection') }}: <v-icon>mdi-hexagon-outline</v-icon></span>
            <v-switch class="d-inline-block"
                      v-model="isolateSelection"
                      @change="toggleSelectionMode()"></v-switch>
            <span><v-icon>mdi-hexagon</v-icon></span>
        </v-card>

        <!--viewer-->
        <div class="ifc-viewer" @dblclick="modelClicked"></div>
    </v-container>
</template>

<script>
import {Color} from 'three';
import {IfcViewerAPI} from 'web-ifc-viewer';
import Properties from '@/pages/Project/Properties.vue';
import ProjectInterface from '@/services/ProjectInterface';
import PropertiesInterface from '@/services/PropertiesInterface';
import PriceInterface from '@/services/PriceInterface';

export default {
    data() {
        return {
            viewer: null,
            modelID: null,
            items: [],
            selectedExpressID: null,
            isolateSelection: true,
            properties: null,
            quantities: null,
            price: null,
            search: null,
            treeClosed: false,
            propsClosed: false,
            loadingProgress: 0
        };
    },
    methods: {
        treeClicked(item) {
            if (item.expressID === this.selectedExpressID) {
                this.deselect();
                return;
            }
            const expressIDs = this.getAllIDs(item);
            this.select(expressIDs);
            this.selectedExpressID = item.expressID;
            this.getProperties();
        },

        modelClicked() {
            const item = this.viewer.context.castRayIfc();
            if (!item) {
                this.deselect();
                return;
            }
            const manager = this.viewer.IFC.loader.ifcManager;
            const expressID = manager.getExpressId(item.object.geometry, item.faceIndex);
            this.select([expressID]);
            this.selectedExpressID = expressID;
            this.getProperties();
        },

        getAllIDs(item) {
            const expressIDs = [];
            const getID = child => {
                if (child.children.length > 0) {
                    child.children.forEach(child => {
                        getID(child);
                    });
                } else {
                    expressIDs.push(child.expressID);
                }
            };
            getID(item);
            return expressIDs;
        },

        select(expressIDs) {
            if (this.isolateSelection) {
                this.viewer.IFC.selector.highlightIfcItemsByID(this.modelID, expressIDs, true);
            } else {
                this.viewer.IFC.selector.pickIfcItemsByID(this.modelID, expressIDs, true);
            }
        },

        deselect() {
            if (this.isolateSelection) {
                this.viewer.IFC.selector.unHighlightIfcItems();
            } else {
                this.viewer.IFC.selector.unpickIfcItems();
            }
            this.selectedExpressID = null;
            this.properties = null;
        },

        getProperties() {
            this.properties = null;
            this.quantities = null;
            this.price = null;
            const fileKey = this.$store.project.files[this.$store.project.activeFile].key;

            PropertiesInterface.getProperties(fileKey, this.selectedExpressID)
                .then(response => this.properties = response);
            PropertiesInterface.getQuantities(fileKey, this.selectedExpressID)
                .then(response => this.quantities = response);
            PriceInterface.getPrice(fileKey, this.selectedExpressID)
                .then(response => this.price = response);
        },

        findByID(expressID) {
            let item = null;
            const findID = child => {
                if (child.expressID === expressID) {
                    item = child;
                    return;
                }
                if (child.children.length > 0) {
                    child.children.forEach(child => findID(child))
                }
            };
            findID(this.items[0], expressID);
            return item;
        },

        toggleSelectionMode() {
            this.viewer.IFC.selector.unHighlightIfcItems();
            this.viewer.IFC.selector.unpickIfcItems();
            if (this.selectedExpressID) {
                const item = this.findByID(this.selectedExpressID);
                const expressIDs = this.getAllIDs(item);
                this.select(expressIDs);
            }
        }
    },
    mounted() {
        ProjectInterface.getFileByProjectId(this.$store.project._id)
            .then(file => loadIfc(file))
            .catch(error => console.error(error));

        const loadIfc = async file => {
            await setupScene();
            const blob = new Blob([file], {type: 'text/plain'});
            const model = await this.viewer.IFC.loadIfc(blob);
            this.modelID = model.modelID;
            const project = await this.viewer.IFC.getSpatialStructure(this.modelID, true);
            this.items = project.children;
        };

        const setupScene = async () => {
            const container = document.querySelector('.ifc-viewer');
            const viewer = new IfcViewerAPI({container, backgroundColor: new Color(0xfafafa)});
            await viewer.IFC.setWasmPath('../');
            const manager = viewer.IFC.loader.ifcManager;
            await manager.useWebWorkers(true, '../../IFCWorker.js');
            manager.setOnProgress(event => {
                const percent = event.loaded / event.total * 100;
                this.loadingProgress = Math.trunc(percent);
            });
            this.viewer = viewer;
        };
    },
    components: {
        Properties
    }
}
</script>

<style lang="scss">
.model-viewer {
    .v-card {
        z-index: 2;
        position: absolute;

        .v-card__text {
            max-height: 500px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .v-card.preloader {
        top: 20px;
        left: calc(50% - 70px);
        width: 140px
    }

    .v-card.tree {
        top: 20px;
        left: 20px;
        width: 400px;

        .v-treeview-node__root {
            min-height: 25px !important;
        }

        .v-treeview-node__level {
            width: 15px !important;
        }

        .v-treeview-node__label .v-icon.v-icon {
            font-size: 1.1rem;
            margin-right: 5px;
        }

        .v-treeview-node__content .v-treeview-node__label {
            font-size: 0.875rem;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }
    }

    .v-card.props {
        top: 20px;
        right: 20px;
        width: 400px;

        table {
            font-size: 0.875rem;
            font-weight: 400;
            font-family: "Roboto", sans-serif;

            th {
                text-align: left;
                text-transform: capitalize;
                font-size: 10px;
                font-weight: bold;
                padding-right: 10px;
            }
        }
    }

    .open-tree,
    .open-props {
        z-index: 2;
        position: absolute;
        top: 20px;
    }

    .open-tree {
        left: 20px;
    }

    .open-props {
        right: 20px;
    }

    .ifc-viewer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        outline: none;
    }

    .controls {
        z-index: 2;
        position: absolute;
        bottom: 20px;
        left: calc(50% - 100px);

        .v-icon {
            margin-bottom: 6px;

            &:first-child {
                margin-right: 6px;
            }
        }
    }
}
</style>
