class ComponentInterface {
    static getAuthHeader() {
        return {'authorization': 'Bearer ' + localStorage.getItem('token')};
    }

    static getAllComponents() {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/component`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static createComponent(body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/component`, {
            method: 'POST',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static getComponent(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/component/${id}`, {
            method: 'GET',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }

    static updateComponent(id, body) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/component/${id}`, {
            method: 'PUT',
            headers: {...this.getAuthHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    static removeComponent(id) {
        return fetch(`${process.env.VUE_APP_BACKEND_HOST}/api/component/${id}`, {
            method: 'DELETE',
            headers: this.getAuthHeader(),
        }).then(response => response.json());
    }
}

export default ComponentInterface;
